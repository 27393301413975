import { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import Logger from '../../../../../../../Utils/Logger';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import moment from 'moment';

const RevenueChart = ({ dataChart, URL }) => {
  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [revenueData, setRevenueData] = useState({});
  const [totalRevenueData, setTotalRevenueData] = useState({});

  const handleChange = (event) => {
    const value = event.target.value;
    setDateRange(value);

    const today = moment();
    let start;

    switch (value) {
      case '15_days':
        start = moment().subtract(15, 'days');
        break;
      case '1_month':
        start = moment().subtract(1, 'month');
        break;
      case '3_months':
        start = moment().subtract(3, 'months');
        break;
      case '6_months':
        start = moment().subtract(6, 'months');
        break;
      case 'custom':
        setStartDate(null);
        setEndDate(null);
        break;
      default:
        start = today;
    }
    if (value !== 'custom') {
      setStartDate(start.format('YYYY-MM-DD')); // Format the date as 'YYYY-MM-DD'
      setEndDate(today.format('YYYY-MM-DD')); // Format the date as 'YYYY-MM-DD'
    }
  };

  useEffect(() => {
    const event = {
      target: {
        value: '15_days',
      },
    };
    handleChange(event);
  }, []);

  useEffect(() => {
    if (dataChart?.id && startDate && endDate) {
      fetchStatsData();
    }
  }, [startDate, endDate]);

  // Revenu Stats
  const fetchStatsData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        URL +
          `${dataChart.id}/graph?start_date=${startDate}&end_date=${endDate}`
      );
      if (response.data.code === 200) {
        if (
          Array.isArray(response.data.data.graphData) &&
          response.data.data.graphData.length === 0
        ) {
          // Create default chartData with x between start date and end date
          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);
          const chartData = [];

          // Generate dates between startDate and endDate
          for (
            let d = startDateObj;
            d <= endDateObj;
            d.setDate(d.getDate() + 1)
          ) {
            chartData.push({
              periodFormat: moment(d).format('MMM DD YYYY'), // Format date as "Nov 19 2024" // Format date as YYYY-MM-DD
              totalRevenue: 0, // Default Revenue
              totalProfit: 0, // Default Profit
            });
          }
          setRevenueData(chartData);
          setTotalRevenueData({});
        } else {
          setRevenueData(response?.data?.data?.graphData);
          setTotalRevenueData(response?.data?.data?.totals);
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Transform the data for the chart
  const chartData = Object.keys(revenueData).map((key) => ({
    time: revenueData[key].periodFormat,
    Revenue: revenueData[key].totalRevenue,
    RevenueFormat: revenueData[key].totalRevenueFormat,
    Profit: revenueData[key].totalProfit,
    ProfitFormat: revenueData[key].totalProfitFormat,
  }));

  return (
    <div className="row g-4 align-items-center mb-4">
      <div className="col-12 col-xl-4">
        <div className="d-flex align-items-center gap-2 mb-3">
          <h3 className="mb-0 text-nowrap">
            {Object.keys(totalRevenueData).length > 0
              ? totalRevenueData?.totalRevenueFormat.trim()
              : '$0.00'}
          </h3>
          <p className="mb-0 text-success bg-light-success px-2 rounded py-1 d-none">
            +10.6%
          </p>
        </div>
        <div>
          Date Range:{' '}
          <select
            className="form-control mb-2"
            value={dateRange}
            onChange={handleChange}
            required
          >
            <option value="15_days">Last 15 Days</option>
            <option value="1_month">Last 1 Month</option>
            <option value="3_months">Last 3 Months</option>
            <option value="6_months">Last 6 Months</option>
            <option value="custom">Custom Date Range</option>
          </select>
          {dateRange === 'custom' && (
            <div>
              <p>
                Start Date:{' '}
                <input
                  type="date"
                  className="form-control mb-2"
                  value={startDate}
                  onChange={(e) => {
                    const newStartDate = e.target.value;
                    setStartDate(newStartDate); // Update start date state

                    // Calculate the new end date, 365 days from the start date
                    const startDateObj = new Date(newStartDate);
                    const newEndDate = new Date(startDateObj);
                    newEndDate.setDate(startDateObj.getDate() + 365); // Add 365 days

                    // Format the new end date to 'YYYY-MM-DD'
                    const formattedEndDate = newEndDate
                      .toISOString()
                      .split('T')[0];
                    setEndDate(formattedEndDate); // Update end date state
                  }} // Update state on change
                />
              </p>
              <p>
                End Date:{' '}
                <input
                  type="date"
                  className="form-control mb-2"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)} // Allow manual change if needed
                />
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        className="col-12 col-xl-8"
        style={{
          height: '270px',
        }}
      >
        {!isLoading && Object.keys(revenueData).length > 0 && (
          <ResponsiveContainer style={{ width: '100%' }} height={270}>
            <AreaChart data={chartData}>
              <defs>
                <linearGradient id="colorSeries1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0288d1" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#0288d1" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorSeries2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00c853" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#00c853" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="time" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="Revenue"
                stroke="#0288d1"
                fillOpacity={1}
                fill="url(#colorSeries1)"
              />
              <Area
                type="monotone"
                dataKey="Profit"
                stroke="#00c853"
                fillOpacity={1}
                fill="url(#colorSeries2)"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

RevenueChart.propTypes = {
  URL: PropTypes.string,
  dataChart: PropTypes.object,
  revenue: PropTypes.string,
};

export default RevenueChart;
