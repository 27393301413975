import { useEffect, useState } from 'react';
import AddressAutoComplete from '../Pages/Dashboard/Components/AddressAutoComplete';
import PropTypes from 'prop-types';
import Logger from '../../Utils/Logger';
import axiosInstance from '../../Utils/axiosInstance';
import { CITIES_STATE_COUNTRY_ENDPOINT } from '../../Utils/Endpoints';

const AddressForm = ({
  handlePreviousStep,
  handleNextStep,
  setLocationType,
  locationType,
  setSelectedAddress,
  setSelectedCity,
  locationName,
  setLocationName,
  city,
  setCity,
  address,
  setAddress,
  selectedAddress,
}) => {
  const [cityList, setCityList] = useState([]);
  // const [city, setCity] = useState('');
  // const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(CITIES_STATE_COUNTRY_ENDPOINT);
      if (response.data.code === 200) {
        setCityList(response.data.data);
      }
    } catch (error) {
      Logger.error(error, isLoading);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCity = (e) => {
    setCity(e.target.value);
    const selected = cityList.find(
      (item) => item?.city_state_country === e.target.value
    );
    setSelectedCity(selected);
  };

  return (
    <>
      <ul className="mb-3 nav nav-tabs nav-warning" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className={`nav-link ${locationType === 'SPECIFIC_LOCATION' ? 'active' : ''}`}
            data-bs-toggle="tab"
            href="#dangerhome"
            role="tab"
            aria-selected={locationType === 'SPECIFIC_LOCATION'}
            onClick={() => setLocationType('SPECIFIC_LOCATION')} // Set locationType to SPECIFIC_LOCATION
          >
            <div className="d-flex align-items-center">
              <div className="tab-title">Specific location</div>
            </div>
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className={`nav-link ${locationType === 'CITY' ? 'active' : ''}`}
            data-bs-toggle="tab"
            href="#dangerProfile"
            role="tab"
            aria-selected={locationType === 'CITY'}
            onClick={() => setLocationType('CITY')} // Set locationType to CITY
            tabIndex="-1"
          >
            <div className="d-flex align-items-center">
              <div className="tab-title">I only got a City</div>
            </div>
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div
          className={`tab-pane fade ${locationType === 'SPECIFIC_LOCATION' ? 'show active' : ''}`}
          id="dangerhome"
          role="tabpanel"
        >
          {locationType === 'SPECIFIC_LOCATION' && (
            <div className="row g-3 mt-3">
              <div className="col-12">
                <label htmlFor="cityName" className="form-label">
                  City
                </label>
                <select
                  className="form-control select-form form-select"
                  id="cityName"
                  required
                  value={city}
                  onChange={handleCity}
                >
                  <option value="">City *</option>
                  {cityList.map((city, index) => (
                    <option value={city.city_state_country} key={index}>
                      {city.city_state_country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12">
                <label htmlFor="InputUsername" className="form-label">
                  Address
                </label>
                <AddressAutoComplete
                  value={address}
                  onAddressChange={setAddress}
                  setSelectedAddress={setSelectedAddress}
                />
              </div>
              <div className="col-12">
                <label htmlFor="locationName" className="form-label">
                  Location Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="locationName"
                  value={locationName}
                  required
                  onChange={(e) => setLocationName(e.target.value)}
                  placeholder="Location Name*"
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={`tab-pane fade ${locationType === 'CITY' ? 'show active' : ''}`}
          id="dangerProfile"
          role="tabpanel"
        >
          <div className="col-12 mt-3">
            <label htmlFor="cityName" className="form-label">
              City
            </label>
            <select
              className="form-control select-form form-select"
              id="cityName"
              required
              value={city}
              onChange={handleCity}
            >
              <option value="">City *</option>
              {cityList.map((city, index) => (
                <option value={city.city_state_country} key={index}>
                  {city.city_state_country}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="d-flex align-items-center gap-3">
            <button
              className="btn btn-outline-secondary px-4 text-nowrap"
              onClick={handlePreviousStep}
              type="button"
            >
              <i className="bx bx-left-arrow-alt me-2"></i>
              Previous
            </button>
            <button
              className="btn btn-outline-warning px-4 text-nowrap"
              onClick={handleNextStep}
              disabled={
                locationType === 'SPECIFIC_LOCATION' &&
                Object.keys(selectedAddress).length > 0
                  ? !selectedAddress?.zip_code
                  : false
              }
              type="button"
            >
              Next<i className="bx bx-right-arrow-alt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

AddressForm.propTypes = {
  handlePreviousStep: PropTypes.func,
  handleNextStep: PropTypes.func,
  locationType: PropTypes.string,
  setLocationType: PropTypes.func,
  setSelectedAddress: PropTypes.func,
  setSelectedCity: PropTypes.func,
  locationName: PropTypes.func,
  setLocationName: PropTypes.func,
  city: PropTypes.string,
  setCity: PropTypes.func,
  address: PropTypes.string,
  setAddress: PropTypes.func,
  selectedAddress: PropTypes.object,
};
export default AddressForm;
