import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SideNav from './SideNav';
import TopNav from './TopNav';

const OrgLayout = ({ children }) => {
  const handleOverlayClick = () => {
    const wrapp = document.getElementsByClassName('wrapper');
    Array.from(wrapp).forEach((element) => {
      element.classList.remove('toggled');
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const menu = document.getElementsByClassName('wrapper')[0];
      const toggleIcon = document.getElementsByClassName('nav-toggle-icon')[0];

      if (
        menu &&
        !menu.contains(event.target) &&
        toggleIcon &&
        !toggleIcon.contains(event.target)
      ) {
        handleOverlayClick();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <SideNav />
        <TopNav />
        <div className="page-content-wrapper">
          <div className="page-content">{children}</div>
        </div>
        <div
          className="overlay nav-toggle-icon"
          onClick={handleOverlayClick}
        ></div>
      </div>
    </>
  );
};
OrgLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrgLayout;
