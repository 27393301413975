import React from 'react';
import OrgLayout from '../Components/OrgLayout';
import { useLocation } from 'react-router-dom';
import MapComponent from './Components/MapComponent';

const PreviewMode = () => {
  const location = useLocation();
  const eventObject = location.state && location.state.event;

  return (
    <>
      <OrgLayout>
        <div className="card p-3">
          <h6 className="text-capitalize">{eventObject?.organization?.name}</h6>
          <p className="font-22 pt-3 text-capitalize">{eventObject?.name}</p>
          <hr />
          <div className="card-body">
            <div className="row">
              <div className="col-xl-4">
                <div className="card-images-text">
                  <div className="preview-image">
                    <img
                      src={
                        eventObject?.banner?.fullUrl
                          ? eventObject?.banner?.fullUrl
                          : '../images/event-img-1.jpg'
                      }
                      alt=""
                    />
                  </div>
                  <div className="details-dd">
                    <h4>Details</h4>
                    <div className="listing-name pt-4">
                      <ul>
                        <li>
                          <h5>
                            Start:
                            <span>{eventObject?.startDateTimeFormat}</span>
                          </h5>
                        </li>
                        <li>
                          <h5>
                            End:
                            <span>{eventObject?.endDateTimeFormat}</span>
                          </h5>
                        </li>
                        <li>
                          <h5>
                            Type:<span>{eventObject?.locationTypeName}</span>
                          </h5>
                        </li>
                        <li className="d-none">
                          <h5>
                            Type:<span>Open</span>
                          </h5>
                        </li>
                        <li className="d-none">
                          <h5>
                            Entrants:<span>100</span>
                          </h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="iframe-and-text">
                  <div className="map-location">
                    <MapComponent
                      lat={eventObject?.location?.lat}
                      lng={eventObject?.location?.lng}
                      city={
                        eventObject?.location_type === 'CITY'
                          ? eventObject?.location?.addressString
                          : null
                      }
                    />
                  </div>
                  {eventObject?.description && (
                    <div className="details-dd">
                      <h4>About</h4>
                      <p className="mb-0 pb-3 pt-4">
                        {eventObject?.description}
                        {/* Lorem ipsum dolor sit amet, est ad graecis principes. Ad
                      vis iisque saperet. Eu eos quod affert. Vim invidunt
                      efficiendi ea, eu eos veniam percipit dignissim, an cum
                      suas laudem. Eum eu ipsum mentitum delectus. Te vix solet
                      consulatu expetendis. Dictas elige ndi antiopam has ne,
                      admodum hendrerit eu vis, sit nonumy oporere eu. Ei qui
                      solet offendit. Ius no graeco possim aeterno, eam at
                      omnium diceret accumsan. Eu nec iisque utroque, ad qui
                      veniam hendrerit. Quas pertinax vulputate cu mea, no duo
                      ubique. */}
                      </p>
                      <p className="mb-0 pb-3">
                        Lorem ipsum dolor sit amet, est ad graecis principes. Ad
                        vis iisque saperet. Eu eos quod affert. Vim invidunt
                        efficiendi ea, eu eos veniam percipit dignissim,
                      </p>
                      <p className="mb-0 pb-3">
                        Lorem ipsum dolor sit amet, est ad graecis principes. Ad
                        vis iisque saperet. Eu eos quod affert. Vim invidunt
                        efficiendi ea, eu eos veniam percipit dignissim,
                      </p>
                      <p className="mb-0 pb-3">
                        Lorem ipsum dolor sit amet, est ad graecis principes. Ad
                        vis iisque saperet. Eu eos quod affert. Vim invidunt
                        efficiendi ea, eu eos veniam percipit dignissim,
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-mode d-none">
          <p>
            There&lsquo;s a chance this page is not yet published. Don&lsquo;t
            share this link with anyone.
          </p>
        </div>
      </OrgLayout>
    </>
  );
};

export default PreviewMode;
