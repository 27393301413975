import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CardEvent = (props) => {
  return (
    <>
      <div className="col">
        <Link to="/edit-events-user" className="card radius-10 d-none">
          <div className="row g-0 align-items-center">
            <div className="col-md-4">
              <div className="px-3 py-md-0 py-3">
                <img
                  src={props.userImage}
                  className="img-fluid radius-10 w-100"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="card-body  h-100">
                <div className="d-flex align-items-center gap-1">
                  <span className="badge bg-light text-light">
                    {props.badges}
                  </span>
                  <span className="text-warning ps-2 d-flex align-items-center">
                    <ion-icon name="star"></ion-icon>
                    {props.admin}
                  </span>
                </div>
                <div className="d-flex align-items-center gap-2 mt-2">
                  <h5 className="card-title mb-0">{props.name}</h5>
                  <Link to="">
                    <ion-icon name="create-outline"></ion-icon>
                  </Link>
                </div>
                <p className="mb-0 text-secondary">{props.titleFirst}</p>
                <p className="mb-0 text-secondary">{props.titleSecond} </p>
                <div className="mt-2">
                  <Link
                    to="#"
                    className="btn btn-sm btn-outline-warning px-3 me-2"
                  >
                    <ion-icon
                      name="link-sharp"
                      role="img"
                      className="md hydrated"
                      aria-label="link sharp"
                    ></ion-icon>
                    Star
                  </Link>
                  <Link to="#" className="btn btn-sm btn-outline-warning px-3">
                    <ion-icon
                      name="eye-outline"
                      role="img"
                      className="md hydrated"
                      aria-label="link sharp"
                    ></ion-icon>
                    Preview
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="col mb-md-0 mb-3 h-100">
          <Link to="/edit-events-user" state={{ event: props.event }}>
            <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
              <img
                src={props.userImage}
                className="card-img-top empty org-event-img"
                alt="..."
              />
              <div className="card-body border-bottom h-100">
                <div className="d-flex align-items-center gap-2 mt-2">
                  <h5 className="card-title">{props.name}</h5>
                </div>
                <p className="card-text">{props.titleFirst}</p>
                <p className="card-text">{props.titleSecond}</p>
              </div>
              <div className="card-body border-top">
                <Link
                  to="/edit-events-user"
                  state={{ event: props.event }}
                  className="btn btn-sm btn-outline-warning px-3"
                >
                  Edit Detail
                </Link>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
CardEvent.propTypes = {
  props: PropTypes.object,
  userImage: PropTypes.string,
  badges: PropTypes.string,
  admin: PropTypes.string,
  titleFirst: PropTypes.string,
  titleSecond: PropTypes.string,
  name: PropTypes.string,
  event: PropTypes.object,
};

export default CardEvent;
