import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import moment from 'moment';

const DateTimePicker = ({ value, onChange, minDate }) => {
  return (
    <div>
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={(date) => onChange(moment(date).format('YYYY-MM-DDTHH:mm'))}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="yyyy-MM-dd HH:mm"
        minDate={minDate}
        className="form-control"
        placeholderText="Select date and time"
        showIcon
        icon={
          <div>
            <ion-icon name="calendar-outline"></ion-icon>
          </div>
        }
        toggleCalendarOnIconClick
        required
      />
    </div>
  );
};

DateTimePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired, // Added required
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // Adjusted prop type
};

export default DateTimePicker;
