import { useEffect, useRef, useState } from 'react';
import Stepper from 'bs-stepper';
// import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import {
  EVENT_TICKET_ENDPOINT,
  TICKET_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateTimePicker from '../../../../../../Components/DateTimePicker';

const AddTicketType = ({ event, handleAdd, fetchTicket, selectedTicekt }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [ticketName, setTicketName] = useState('');
  const [description, setDescription] = useState('');
  const [priceType, setPriceType] = useState('');
  const [ticketPrice, setTicketPrice] = useState('');
  const [serviceType, setServiceType] = useState('');
  // Step 2
  // const [totalNumberTicket, setTotalNumberTicket] = useState('');
  const [minTicket, setMinTicket] = useState('');
  const [maxTicket, setMaxTicket] = useState('');
  const [status, setStatus] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [stock, setStock] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const formRef1 = useRef(null);
  const formRef2 = useRef(null);
  const formRef3 = useRef(null);
  // const navigate = useNavigate();

  // const handleNavigation = (url) => {
  //   navigate(url);
  // };

  const stepperRef = useRef(null);
  useEffect(() => {
    if (stepperRef.current) {
      // Initialize your stepper here
      new Stepper(stepperRef.current);
    }
  }, [stepperRef]);

  useEffect(() => {
    if (selectedTicekt?.id) {
      setTicketName(selectedTicekt.name);
      setDescription(selectedTicekt.description);
      setPriceType(selectedTicekt.price === 0 ? 'FREE' : 'PRICE');
      setTicketPrice(selectedTicekt.price);
      setStock(selectedTicekt.stock);
      setMaxTicket(selectedTicekt.maximum_order_qty);
      setMinTicket(selectedTicekt.minimum_order_qty);
      setStartDateTime(
        moment
          .unix(selectedTicekt?.selling_start_date)
          .format('YYYY-MM-DDTHH:mm')
      );
      setEndDateTime(
        moment.unix(selectedTicekt?.selling_end_date).format('YYYY-MM-DDTHH:mm')
      );
      setServiceType(selectedTicekt?.fee_to_customer === 1 ? 'PASS' : 'ABSORB');
      setStatus(selectedTicekt.status);
    }
  }, [selectedTicekt]);

  const handleNextStep = (e) => {
    e.preventDefault();
    const form =
      currentStep === 1
        ? formRef1.current
        : currentStep === 2
          ? formRef2.current
          : formRef3.current;
    if (form.checkValidity()) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      form.reportValidity();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      const requestObject = {
        name: ticketName,
        description: description,
        price: priceType === 'PRICE' ? ticketPrice : 0,
        stock: stock,
        minimum_order_qty: minTicket,
        maximum_order_qty: maxTicket,
        selling_start_date: moment(startDateTime).unix(),
        selling_end_date: moment(endDateTime).unix(),
        fee_to_customer: serviceType === 'ABSORB' ? 0 : 1,
        status: status,
      };

      let response;
      if (selectedTicekt?.id) {
        response = await axiosInstance.patch(
          `${TICKET_ENDPOINT}${selectedTicekt.id}`,
          requestObject
        );
      } else {
        response = await axiosInstance.post(
          EVENT_TICKET_ENDPOINT + `${event?.id}/ticket`,
          requestObject
        );
      }
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      await handleAdd();
      await handleClear();
      await fetchTicket();
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setTicketName('');
    setDescription('');
    setPriceType('');
    setTicketPrice('');
    setStock('');
    setMaxTicket('');
    setMinTicket('');
    setStartDateTime('');
    setEndDateTime('');
    setServiceType('');
    setStatus('');
    setCurrentStep(1);
  };

  return (
    <div id="stepper1" className="bs-stepper" ref={stepperRef}>
      <div className="card">
        <div className="card-header pe-md-5">
          <div
            className="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between tab-ttt"
            role="tablist"
          >
            <div
              className={`step ${currentStep === 1 ? 'active' : ''}`}
              data-target="#test-l-1"
            >
              <div
                className="step-trigger"
                role="tab"
                id="stepper1trigger1"
                aria-controls="test-l-1"
              >
                <div
                  className={`bs-stepper-circle  ${currentStep === 1 ? 't-a' : 't-a-i'}`}
                >
                  1
                </div>
                <div className="s-v">
                  <h5 className="mb-0 steper-title">General</h5>
                  <p className="mb-0 steper-sub-title">Step 1</p>
                </div>
              </div>
            </div>
            <div className="bs-stepper-line"></div>
            <div
              className={`step ${currentStep === 2 ? 'active' : ''}`}
              data-target="#test-l-2"
            >
              <div
                className="step-trigger"
                role="tab"
                id="stepper1trigger2"
                aria-controls="test-l-2"
              >
                <div
                  className={`bs-stepper-circle  ${currentStep === 2 ? 't-a' : 't-a-i'}`}
                >
                  2
                </div>
                <div className="s-v">
                  <h5 className="mb-0 steper-title">Availability</h5>
                  <p className="mb-0 steper-sub-title">Step 2</p>
                </div>
              </div>
            </div>
            <div className="bs-stepper-line"></div>
            <div
              className={`step ${currentStep === 3 ? 'active' : ''}`}
              data-target="#test-l-3"
            >
              <div
                className="step-trigger"
                role="tab"
                id="stepper1trigger3"
                aria-controls="test-l-3"
              >
                <div
                  className={`bs-stepper-circle  ${currentStep === 3 ? 't-a' : 't-a-i'}`}
                >
                  3
                </div>
                <div className="s-v">
                  <h5 className="mb-0 steper-title">Preview</h5>
                  <p className="mb-0 steper-sub-title">Step 3</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="bs-stepper-content">
            <div
              id="test-l-1"
              role="tabpanel"
              className={`bs-stepper-pane ${currentStep === 1 ? 'active' : ''}`}
              aria-labelledby="stepper1trigger1"
            >
              <p className="mb-4 d-none"></p>
              <form ref={formRef1} noValidate>
                <div className="row g-3">
                  <div className="col-12">
                    <label htmlFor="ticketName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ticketName"
                      value={ticketName}
                      required
                      onChange={(e) => setTicketName(e.target.value)}
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      cols="4"
                      placeholder="Description..."
                      value={description}
                      required
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="col-xl-4">
                    <label htmlFor="price-label" className="pb-2">
                      Price
                    </label>

                    <div className="new-price-add">
                      <div className="px-1 mb-2">
                        <div className="ps-3 align-items-center mb-0 bg-secondary-dark border-1 card d-flex flex-row form-check form-check-info hover-border-info p-2 text-center user-select-none">
                          <input
                            className="form-check-input scale"
                            name="priceType"
                            type="radio"
                            required
                            id="freeCheck"
                            value="FREE"
                            checked={priceType === 'FREE'}
                            onChange={(e) => setPriceType(e.target.value)}
                          />
                          <label
                            className="form-check-label pt-1 text-white"
                            htmlFor="freeCheck"
                          >
                            Free
                          </label>
                        </div>
                      </div>
                      <div className=" px-1 mb-2">
                        <div className="ps-3 align-items-center mb-0 bg-secondary-dark border-1 card d-flex flex-row form-check form-check-info hover-border-info p-2 text-center user-select-none">
                          <input
                            className="form-check-input scale"
                            name="priceType"
                            type="radio"
                            id="priceCheck"
                            value="PRICE"
                            checked={priceType === 'PRICE'}
                            onChange={(e) => setPriceType(e.target.value)}
                          />
                          <label
                            className="form-check-label pt-1 text-white"
                            htmlFor="priceCheck"
                          >
                            Price
                          </label>
                        </div>
                      </div>
                      {priceType === 'PRICE' && (
                        <div className=" px-1 full-w-input mb-4">
                          <input
                            type="number"
                            min="0"
                            className="form-control py-2"
                            placeholder="$"
                            style={{ height: '48px' }}
                            required
                            value={ticketPrice}
                            onKeyPress={(e) => {
                              // Prevent the user from typing a minus sign
                              if (e.key === '-' || e.key === '+') {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            onChange={(e) => setTicketPrice(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="row">
                      <label htmlFor="service" className="pb-2">
                        Service
                      </label>
                      <div className="check-box-align">
                        <div className="ps-3 align-items-center mb-0 bg-secondary-dark border-1 card d-flex flex-row form-check form-check-info hover-border-info p-2 text-center user-select-none">
                          <input
                            className="form-check-input scale"
                            name="businessType"
                            type="radio"
                            id="serviceTypePass"
                            required
                            value="PASS"
                            checked={serviceType === 'PASS'}
                            onChange={(e) => setServiceType(e.target.value)}
                          />
                          <label
                            htmlFor="serviceTypePass"
                            className="form-check-label pt-1 text-white text-start"
                          >
                            Pass on the fees to the customer
                          </label>
                        </div>
                        <div className="px-3 align-items-center mb-0 bg-secondary-dark border-1 card d-flex flex-row form-check form-check-info hover-border-info p-2 text-center user-select-none">
                          <input
                            className="form-check-input scale"
                            name="businessType"
                            type="radio"
                            id="serviceTypeAbsorb"
                            value="ABSORB"
                            checked={serviceType === 'ABSORB'}
                            onChange={(e) => setServiceType(e.target.value)}
                          />

                          <label
                            htmlFor="serviceTypeAbsorb"
                            className="form-check-label pt-1 text-white text-start"
                          >
                            Absorb the fees into the ticket price
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-2">
                    <button
                      className="btn btn-outline-warning px-4"
                      onClick={handleNextStep}
                      type="button"
                    >
                      Next
                      <i className="bx bx-right-arrow-alt ms-2"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              id="test-l-2"
              role="tabpanel"
              className={`bs-stepper-pane ${currentStep === 2 ? 'active' : ''}`}
              aria-labelledby="stepper1trigger2"
            >
              <h5 className="mb-1">Quantities</h5>
              <p className="mb-4">
                Set a total number of tickets for this ticket type. You can also
                set a minimum or maximum number of tickets.
              </p>
              <form ref={formRef2} noValidate>
                <div className="row g-3">
                  {/* <div className="col-12 col-xl-4">
                    <label htmlFor="tickets-number" className="form-label">
                      Total Number of Tickets
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="totalNumberTicket"
                      value={totalNumberTicket}
                      required
                      onChange={(e) => setTotalNumberTicket(e.target.value)}
                    />
                  </div> */}
                  <div className="col-12 col-xl-4">
                    <label htmlFor="order" className="form-label">
                      Min tickets per customer
                    </label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      name="minTicket"
                      value={minTicket}
                      required
                      onKeyPress={(e) => {
                        // Prevent the user from typing a minus sign
                        if (e.key === '-' || e.key === '+') {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(e) => setMinTicket(e.target.value)}
                    />
                  </div>
                  <div className="col-12 col-xl-4">
                    <label htmlFor="max" className="form-label">
                      Max tickets per customer
                    </label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      name="maxTicket"
                      value={maxTicket}
                      required
                      onKeyPress={(e) => {
                        // Prevent the user from typing a minus sign
                        if (e.key === '-' || e.key === '+') {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(e) => setMaxTicket(e.target.value)}
                    />
                  </div>
                  <div className="col-12 col-xl-4 mt-3">
                    <label htmlFor="status" className="form-label">
                      Current Status
                    </label>
                    <select
                      name="Status"
                      id="status"
                      className="form-select"
                      value={status}
                      required
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="AVAILABLE">Available</option>
                      <option value="HIDDEN">Hidden</option>
                      <option value="SOLD_OUT">Sold Out</option>
                      <option value="SOON">Soon</option>
                      <option value="SALE_ENDED">Sale Ended</option>
                      <option value="UNAVAILABLE">Un-Available</option>
                    </select>
                  </div>
                  <div className="col-12 col-xl-4 mt-3">
                    <label htmlFor="start time" className="form-label">
                      Start Time
                    </label>
                    <DateTimePicker
                      value={startDateTime}
                      onChange={setStartDateTime}
                      minDate={new Date()}
                    />
                    {/* <input
                      type="datetime-local"
                      className="form-control"
                      id="EventStartDateTime"
                      value={startDateTime}
                      required
                      min={new Date().toISOString().slice(0, 16)}
                      onChange={(e) => setStartDateTime(e.target.value)}
                    /> */}
                  </div>
                  <div className="col-12 col-xl-4 mt-3">
                    <label htmlFor="end time" className="form-label">
                      End Time
                    </label>

                    <DateTimePicker
                      value={endDateTime}
                      onChange={setEndDateTime}
                      minDate={new Date()}
                    />

                    {/* <input
                      type="datetime-local"
                      className="form-control"
                      id="EventEndDateTime"
                      value={endDateTime}
                      required
                      min={new Date().toISOString().slice(0, 16)}
                      onChange={(e) => setEndDateTime(e.target.value)}
                    /> */}
                  </div>
                  <div className="col-12 col-xl-4 mt-3">
                    <label htmlFor="Stork" className="form-label">
                      Stock
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      value={stock}
                      required
                      onKeyPress={(e) => {
                        // Prevent the user from typing a minus sign
                        if (e.key === '-' || e.key === '+') {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(e) => setStock(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-center gap-3 mt-2">
                      <button
                        className="btn btn-outline-secondary px-4 text-nowrap"
                        onClick={handlePreviousStep}
                        type="button"
                      >
                        <i className="bx bx-left-arrow-alt me-2"></i>
                        Previous
                      </button>
                      <button
                        className="btn btn-outline-warning px-4 text-nowrap"
                        onClick={handleNextStep}
                        type="button"
                      >
                        Next
                        <i className="bx bx-right-arrow-alt ms-2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              id="test-l-3"
              role="tabpanel"
              className={`bs-stepper-pane ${currentStep === 3 ? 'active' : ''}`}
              aria-labelledby="stepper1trigger3"
            >
              <h5 className="mb-1 d-none">
                Got an event flyer? You can drop it here!
              </h5>
              <p className="mb-4 d-none">Give your event its unique touch.</p>
              <form ref={formRef3} noValidate>
                <div className="view-ticket">
                  <div className="col">
                    <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                      <div className="card-body border-bottom h-100">
                        <h5 className="card-title">Name: {ticketName}</h5>
                        <p className="card-text">Description: {description}</p>
                        <p>Price: ${ticketPrice}.00</p>
                        <p>Start Date Time: {startDateTime}</p>
                        <p>End Date Time: {endDateTime}</p>
                        <p>Stock: {stock}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center gap-3 mt-3">
                    <button
                      className="btn btn-outline-secondary px-4 text-nowrap"
                      onClick={handlePreviousStep}
                      type="button"
                    >
                      <i className="bx bx-left-arrow-alt me-2"></i>
                      Previous
                    </button>
                    <button
                      className="btn bg-gradient-branding text-dark px-3 px-md-4 text-nowrap"
                      onClick={handleSubmit}
                      type="button"
                      disabled={isLoading}
                    >
                      {isLoading
                        ? 'Loading...'
                        : selectedTicekt?.id
                          ? 'Update'
                          : 'Save'}
                      <i className="bx bx-right-arrow-alt ms-2"></i>
                    </button>
                  </div>
                  {generalError && (
                    <div className="error text-center">{generalError}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AddTicketType.propTypes = {
  event: PropTypes.object,
  handleAdd: PropTypes.func,
  fetchTicket: PropTypes.func,
  selectedTicekt: PropTypes.object,
};

export default AddTicketType;
