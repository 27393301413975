import { useEffect, useState, useRef } from 'react';
// import './ProfileUpdate.css';
import * as Yup from 'yup';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  REMOVE_ENDPOINT,
  UPDATE_PROFILE_ENDPOINT,
} from '../../../../Utils/Endpoints';
import { getLoginSeller, setLoginSeller } from '../../../../Utils/LocalStorage';
import Layout from '../../Dashboard/Components/Layout';
import Toast from '../../../../Utils/Toast';
import { USER_CLASS } from '../../../../Utils/ModelClasses';
import { USER_PROFILE_PICTURE_TYPE } from '../../../../Utils/UploadType';
import { uploadFile } from '../../../../Utils/upload';
import Logger from '../../../../Utils/Logger';

// Validation schema
const ProfileUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  bio: Yup.string().required('About me is required.'),
  lastName: Yup.string().required('Last Name is required'),
  password: Yup.string().test('isChangePassword', 'Required', function (value) {
    if (this.parent.isChangePassword) {
      if (value === '') {
        return this.createError({ message: 'Password is required.' });
      }
      return true;
    }
    return true;
  }),
  confirmPassword: Yup.string().test(
    'isChangePassword',
    'Required',
    function (value) {
      if (this.parent.isChangePassword) {
        if (value === '') {
          return this.createError({ message: 'Confirm Password is required.' });
        }
        if (value !== this.parent.password) {
          return this.createError({ message: 'Passwords must match' });
        }
        return true;
      }
      return true;
    }
  ),
});

const ProfileUpdate = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [dob, setDob] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const seller = getLoginSeller();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Define allowed file types
      const allowedFileTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
      ];
      // Check if the selected file type is allowed
      if (!allowedFileTypes.includes(selectedFile.type)) {
        Toast.error(
          'Invalid file type selected. Please upload a valid image file.',
          {
            autoClose: 2000,
          }
        );
        return;
      }

      setIsImageLoading(true);
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      try {
        const imageData = await uploadFile(
          USER_CLASS,
          seller?.id,
          USER_PROFILE_PICTURE_TYPE,
          selectedFile
        );
        setPreview(imageData?.data?.data?.fullUrl);
        Toast.success(imageData.data.message, {
          autoClose: 2000,
        });
        await handleSubmit(event);
      } catch (error) {
        Logger.error('Error uploading file:', error);
        setPreview(null);
      } finally {
        setIsImageLoading(false);
      }
    }
  };

  const validate = () => {
    try {
      setGeneralError('');
      ProfileUpdateSchema.validateSync(
        {
          firstName,
          lastName,
          password,
          confirmPassword,
          isChangePassword,
          bio,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      setTimeout(() => {
        setErrors({});
      }, 3000);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      let requestObject = {};
      if (isChangePassword) {
        requestObject = {
          first_name: firstName,
          last_name: lastName,
          password: password,
          password_confirmation: confirmPassword,
          username: userName,
          phone_number: phoneNumber,
          dob: dob,
          gender: gender,
          bio: bio ? bio : '',
        };
      } else {
        requestObject = {
          first_name: firstName,
          last_name: lastName,
          username: userName,
          phone_number: phoneNumber,
          dob: dob,
          gender: gender,
          bio: bio ? bio : '',
        };
      }
      const response = await axiosInstance.patch(
        UPDATE_PROFILE_ENDPOINT,
        requestObject
      );
      if (response.data.code === 200) {
        await setLoginSeller(response.data.data);
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (seller && seller?.status !== 'PENDING') {
      setFirstName(seller.first_name);
      setLastName(seller.last_name);
      setUserName(seller.username);
      setGender(seller.gender);
      setBio(seller.bio);
      setDob(seller.dob);
      setEmail(seller.email);
      setPhoneNumber(seller.phone_number);
      setPreview(seller.profile_picture?.fullUrl);
    } else {
      setEmail(seller.email);
      setUserName(seller.username);
      setGender(seller.gender);
      setIsChangePassword(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClosePassword = () => {
    setIsChangePassword(!isChangePassword);
    setConfirmPassword('');
    setPassword('');
    setShowConfirmPassword(false);
    setShowPassword(false);
    setErrors({});
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      setIsImageLoading(true);
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);
      if (response.status === 204) {
        setPreview(null);
        setFile(null);
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
        await handleSubmit(e);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsImageLoading(false);
    }
  };

  return (
    <>
      <Layout>
        <div className="page-breadcrumb d-flex align-items-center mb-3">
          <div className="font-22">My Account</div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="card radius-10">
              <div className="card-body">
                <h6 style={{ color: '#969696' }}>Profile</h6>
                <hr />
                <form className="login-form" onSubmit={handleSubmit}>
                  <div className="mb-4 d-flex gap-3 align-items-center justify-content-start">
                    <div
                      className={`user-change-photo shadow ${isImageLoading ? ' over-flow ' : ''} `}
                    >
                      <img
                        src={preview ? preview : '../images/profile.png'}
                        alt="..."
                      />
                    </div>
                    {!seller?.profile_picture && (
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm radius-0 px-4"
                        onClick={handleButtonClick}
                        disabled={isImageLoading}
                      >
                        <ion-icon name="image-sharp"></ion-icon>Change Photo
                      </button>
                    )}

                    {seller?.profile_picture && (
                      <button
                        type="button"
                        onClick={(e) =>
                          handleDelete(e, seller.profile_picture.id)
                        }
                        disabled={isImageLoading}
                        className="btn btn-outline-danger btn-sm radius-30 px-4"
                      >
                        <ion-icon name="trash-outline"></ion-icon>Remove Photo
                      </button>
                    )}

                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                    />
                  </div>
                  <h5 className="mb-0 mt-4">User Information</h5>
                  <hr />
                  <div className="row g-3">
                    <div className="col-6 d-none">
                      <label className="form-label">UserName</label>
                      <input
                        type="text"
                        className="form-control"
                        name="userName"
                        value={userName}
                        placeholder="User Name*"
                        onChange={(e) => setUserName(e.target.value)}
                        autoComplete="new-password"
                      />
                      {errors.userName && (
                        <div className="error">{errors.userName}</div>
                      )}
                    </div>

                    <div className="col-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={firstName}
                        placeholder="First Name*"
                        onChange={(e) => setFirstName(e.target.value)}
                        autoComplete="new-password"
                      />
                      {errors.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                    <div className="col-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={lastName}
                        placeholder="Last Name*"
                        onChange={(e) => setLastName(e.target.value)}
                        autoComplete="new-password"
                      />
                      {errors.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                    <div className="col-6">
                      <label className="form-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        disabled
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">DOB</label>
                      <input
                        type="date"
                        name="dob"
                        className="form-control"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                      />
                    </div>
                    <div className="col-12">
                      <label className="form-label">Gender</label>
                      <select
                        className="form-select mb-0"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Select Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </div>

                    <div className="col-12">
                      <label className="form-label">About Me</label>
                      <textarea
                        className="form-control"
                        rows="4"
                        cols="4"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        placeholder="Describe yourself..."
                      ></textarea>
                      {errors.bio && <div className="error">{errors.bio}</div>}
                    </div>

                    {!isChangePassword && (
                      <div className="col-lg-3">
                        <div className="tickets">
                          <span
                            onClick={() =>
                              setIsChangePassword(!isChangePassword)
                            }
                            className="c-pass-word text-start pb-3 cp"
                          >
                            Change password
                          </span>
                        </div>
                      </div>
                    )}
                    {isChangePassword && (
                      <>
                        <div className="col-6">
                          <label className="form-label">Password</label>
                          <div className="input-pass-word">
                            <input
                              type={showPassword ? 'text' : 'password'}
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Password*"
                              autoComplete="new-password"
                            />
                            <span onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <img
                                  src="../images/eye-password-hide.svg"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="../images/eye-password-show.svg"
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                          {errors.password && (
                            <div className="error">{errors.password}</div>
                          )}
                        </div>
                        <div className="col-6">
                          <label className="form-label">Confirm Password</label>
                          <div className="input-pass-word">
                            <input
                              type={showConfirmPassword ? 'text' : 'password'}
                              className="form-control"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              placeholder="Confirm Password*"
                              autoComplete="new-password"
                            />
                            <span onClick={toggleConfirmPasswordVisibility}>
                              {showConfirmPassword ? (
                                <img
                                  src="../images/eye-password-hide.svg"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="../images/eye-password-show.svg"
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                          {errors.confirmPassword && (
                            <div className="error">
                              {errors.confirmPassword}
                            </div>
                          )}
                          {seller?.first_name && (
                            <span
                              onClick={handleClosePassword}
                              className="c-pass-word cp text-end d-block pt-2"
                            >
                              Close
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h5 className="mb-0 mt-4 d-none">Contact Information</h5>
                  <hr />
                  <div className="row g-3 d-none">
                    <div className="col-12">
                      <label className="form-label">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value="47-A, city name, United States"
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        className="form-control"
                        value="@jhon"
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        value="xyz@example.com"
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Pin Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value="jhon"
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <label className="form-label">About Me</label>
                      <textarea
                        className="form-control"
                        rows="4"
                        cols="4"
                        placeholder="Describe yourself..."
                      ></textarea>
                    </div>
                  </div>
                  {generalError && <div className="error">{generalError}</div>}
                  <div className="text-start mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary px-4"
                      disabled={isLoading || isImageLoading}
                    >
                      {isLoading ? 'Loading...' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body">
                <h6 className="" style={{ color: '#969696' }}>
                  Setting
                </h6>

                <hr />
                <div className="setting-stripe">
                  <button type="submit" className="btn btn-outline-info px-4">
                    Connect stripe
                  </button>
                </div>

                <div className="card d-none">
                  <div className="car-body p-3">
                    <div className="stripe-account">
                      <div className="shield">
                        <ion-icon name="shield-outline"></ion-icon>
                      </div>
                      <div className="">
                        <p>Ajay Kumar</p>
                        <p>xxxx xxxx xxxx 4242</p>
                        <p>
                          <span>09/30 </span>
                          <span>Visa</span>
                        </p>
                      </div>

                      <div className="ms-auto">
                        <div className="widget-icon rounded-circle bg-light-danger text-danger">
                          <ion-icon
                            name="trash-outline"
                            role="img"
                            classname="md hydrated"
                            class="md hydrated"
                          ></ion-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProfileUpdate;
