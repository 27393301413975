import React from 'react';
import PropTypes from 'prop-types';

const NotFoundPage = ({ title, isInfo }) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="">
            <div className="card-body text-center py-5">
              <img src="../images/cross-not-found.svg" alt="not-found-image" />
              <div className="font-22 pt-3">No {title} Found</div>
              {!isInfo && (
                <p className="d-none">Create a new {title} to get Started</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

NotFoundPage.propTypes = {
  title: PropTypes.string,
  isInfo: PropTypes.bool,
};

export default NotFoundPage;
