import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getObject } from '../../../../../../Utils/LocalStorage';
import DeleteModal from '../../../Components/DeleteModal';

const TopNav = () => {
  const [organisation, setOrganisation] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const data = getObject('selectedOrganization');
    setOrganisation(data);
  }, []);

  const handleDelete = () => {
    navigate('/organiziations');
  };

  const handleMenuCollaps = () => {
    const wrapp = document.getElementsByClassName('wrapper');
    // Convert HTMLCollection to an array and iterate over it
    Array.from(wrapp).forEach((element) => {
      element.classList.toggle('toggled'); // Add or remove the 'toggled' class
    });
  };

  return (
    <>
      <header className="top-header">
        <nav className="navbar navbar-expand gap-3">
          <div className="toggle-icon" onClick={handleMenuCollaps}>
            <ion-icon name="menu-outline"></ion-icon>
          </div>
          <h5 className="mb-0 text-capitalize h-t-text h-t-text-2">
            {organisation && organisation?.name}
          </h5>

          <form className="searchbar d-none">
            <div className="position-absolute top-50 translate-middle-y search-icon ms-3">
              <ion-icon name="search-outline"></ion-icon>
            </div>
            <input
              className="form-control"
              type="text"
              placeholder="Search for anything"
            />
            <div className="position-absolute top-50 translate-middle-y search-close-icon">
              <ion-icon name="close-outline"></ion-icon>
            </div>
          </form>
          {pathname !== '/edit-events-user' && (
            <>
              <div className="d-lg-block d-none ms-auto">
                <div className="top-navbar-right ms-auto mm-cc ">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    // onClick={organization}
                    onClick={() =>
                      setSelectedData({
                        id: organisation?.id,
                        type: 'organization',
                      })
                    }
                  >
                    <div className="d-flex align-items-center gap-2">
                      <span className="widget-icon rounded-circle bg-light-danger text-danger">
                        <ion-icon
                          name="trash-outline"
                          role="img"
                          className="md hydrated"
                        ></ion-icon>
                      </span>
                      <p className="text-danger"> Delete Organization</p>
                    </div>
                  </Link>
                  <Link to="/org-event-create">
                    <div className="d-flex align-items-center gap-2">
                      <span className="widget-icon rounded-circle bg-light-info text-info">
                        <ion-icon
                          name="add-outline"
                          role="img"
                          className="md hydrated"
                        ></ion-icon>
                      </span>
                      <p className="text-info"> Create Event</p>
                    </div>
                    {/* <span>
                <ion-icon name="add-outline"></ion-icon>
              </span>
              Create Event */}
                  </Link>
                </div>
              </div>
              <div className="d-lg-none d-block ms-auto">
                <div className="top-navbar-right ms-auto mm-cc">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    // onClick={organization}
                    onClick={() =>
                      setSelectedData({
                        id: organisation?.id,
                        type: 'organization',
                      })
                    }
                  >
                    <div className="d-flex align-items-center">
                      <span className="widget-icon rounded-circle bg-light-danger text-danger icon-w-set">
                        <ion-icon
                          name="trash-outline"
                          role="img"
                          className="md hydrated"
                        ></ion-icon>
                      </span>
                    </div>
                  </Link>
                  <Link to="/org-event-create">
                    <div className="d-flex align-items-center">
                      <span className="widget-icon rounded-circle bg-light-info text-info icon-w-set">
                        <ion-icon
                          name="add-outline"
                          role="img"
                          className="md hydrated"
                        ></ion-icon>
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )}
          <div className="top-navbar-right d-none">
            <ul className="navbar-nav align-items-center mt-0">
              <li className="nav-item d-none">
                <Link className="nav-link mobile-search-button">
                  <div className="">
                    <ion-icon name="search-outline"></ion-icon>
                  </div>
                </Link>
              </li>
              <li className="nav-item dropdown dropdown-large d-none">
                <Link
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  to=""
                  data-bs-toggle="dropdown"
                >
                  <div className="position-relative">
                    <span className="notify-badge">8</span>
                    <ion-icon name="notifications-outline"></ion-icon>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link to="">
                    <div className="msg-header">
                      <p className="msg-header-title">Notifications</p>
                      <p className="msg-header-clear ms-auto">
                        Marks all as read
                      </p>
                    </div>
                  </Link>
                  <div className="header-notifications-list">
                    <Link className="dropdown-item" to="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-primary">
                          <ion-icon name="cart-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Orders{' '}
                            <span className="msg-time float-end">
                              2 min ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            You have recived new orders
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link className="dropdown-item" to="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-danger">
                          <ion-icon name="person-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Customers
                            <span className="msg-time float-end">
                              14 Sec ago
                            </span>
                          </h6>
                          <p className="msg-info">5 new user registered</p>
                        </div>
                      </div>
                    </Link>
                    <Link className="dropdown-item" to="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-success">
                          <ion-icon name="document-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            24 PDF File
                            <span className="msg-time float-end">
                              19 min ago
                            </span>
                          </h6>
                          <p className="msg-info">The pdf files generated</p>
                        </div>
                      </div>
                    </Link>

                    <Link className="dropdown-item" to="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-info">
                          <ion-icon name="checkmark-done-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Product Approved{' '}
                            <span className="msg-time float-end">
                              2 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            Your new product has approved
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link className="dropdown-item" to="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-warning">
                          <ion-icon name="send-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Time Response{' '}
                            <span className="msg-time float-end">
                              28 min ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            5.1 min avarage time response
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="dropdown-item">
                      <div className="d-flex align-items-center">
                        <div className="notify text-danger">
                          <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Comments{' '}
                            <span className="msg-time float-end">
                              4 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            New customer comments recived
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="dropdown-item">
                      <div className="d-flex align-items-center">
                        <div className="notify text-primary">
                          <ion-icon name="albums-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New 24 authors
                            <span className="msg-time float-end">
                              1 day ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            24 new authors joined last week
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="dropdown-item">
                      <div className="d-flex align-items-center">
                        <div className="notify text-success">
                          <ion-icon name="shield-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Your item is shipped{' '}
                            <span className="msg-time float-end">
                              5 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            Successfully shipped your item
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="dropdown-item">
                      <div className="d-flex align-items-center">
                        <div className="notify text-warning">
                          <ion-icon name="cafe-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Defense Alerts{' '}
                            <span className="msg-time float-end">
                              2 weeks ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            45% less alerts last 4 weeks
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <Link to="" className="dropdown-item">
                    <div className="text-center msg-footer">
                      View All Notifications
                    </div>
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown dropdown-user-setting">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  href=""
                  data-bs-toggle="dropdown"
                >
                  <div className="user-setting">
                    <img
                      src={
                        organisation?.banner?.fullUrl
                          ? organisation?.banner?.fullUrl
                          : '../images/thumbnail.png'
                      }
                      className="user-img"
                      alt="org"
                    />
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  {/* <li>
                    <a className="dropdown-item">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <img
                          src={
                            organisation?.banner?.fullUrl
                              ? organisation?.banner?.fullUrl
                              : '../images/product.png'
                          }
                          alt=""
                          className="rounded-circle"
                          width="54"
                          height="54"
                        />
                        <div className="">
                          <h6 className="mb-0 dropdown-user-name">
                            {organisation && organisation?.name}
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li> */}
                  <li>
                    <a
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      // onClick={organization}
                      onClick={() =>
                        setSelectedData({
                          id: organisation?.id,
                          type: 'organization',
                        })
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="">
                          <ion-icon name="trash-outline"></ion-icon>
                        </div>
                        <div className="ms-3">
                          <span>Delete</span>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/org-event-create">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <ion-icon name="add-outline"></ion-icon>
                        </div>
                        <div className="ms-3">
                          <span>Create Event</span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={handleDelete}
      />
    </>
  );
};

export default TopNav;
