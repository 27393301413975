export const API_BASE_URL = process.env.REACT_APP_API_URL + '/api/v1/';

export const LOGIN_ENDPOINT = `${API_BASE_URL}login`;
export const SIGNUP_STEP_ONE_ENDPOINT = `${API_BASE_URL}auth/login/step/one`;
export const SIGNUP_STEP_TWO_ENDPOINT = `${API_BASE_URL}auth/login/step/two`;
export const FORGOT_ENDPOINT = `${API_BASE_URL}password/forgot`;
export const RESET_ENDPOINT = `${API_BASE_URL}password/reset`;
export const UPDATE_PROFILE_ENDPOINT = `${API_BASE_URL}profile/update`;
export const ORGANIZATION_ENDPOINT = `${API_BASE_URL}organizations`;
export const ORGANIZATION_EVENT_ENDPOINT = `${API_BASE_URL}organization/`;
export const EVENTS_ENDPOINT = `${API_BASE_URL}organization/events`;
export const EVENT_TICKET_ENDPOINT = `${API_BASE_URL}event/`;
export const TICKET_ENDPOINT = `${API_BASE_URL}event/ticket/`;
export const PROMOCODE_ENDPOINT = `${API_BASE_URL}promo-code/`;
export const SOCIAL_LINK_ENDPOINT = `${API_BASE_URL}social-link/list`;
export const SOCIAL_LINK_SETUP_ENDPOINT = `${API_BASE_URL}social-link/setup`;
export const TAG_LIST_ENDPOINT = `${API_BASE_URL}tag/search`;
export const TAG_ENDPOINT = `${API_BASE_URL}tag`;
export const UPLOAD_ENDPOINT = `${API_BASE_URL}file/upload`;
export const REMOVE_ENDPOINT = `${API_BASE_URL}file/remove/`;
export const CITIES_STATE_COUNTRY_ENDPOINT = `${API_BASE_URL}cities-with-states-with-country`;
export const QR_CODE_ENDPOINT = `${API_BASE_URL}qr-code`;

// Stats
export const DASHBOARD_STATS_ENDPOINT = `${API_BASE_URL}organization/dashboard/stats`;
export const ORGANIZATION_STATS_ENDPOINT = `${API_BASE_URL}organization/`;
export const EVENT_STATS_ENDPOINT = `${API_BASE_URL}event/`;
export const USER_LIST_ENDPOINT = `${API_BASE_URL}guests`;
export const GUESTS_LIST_ENDPOINT = `${API_BASE_URL}organization/order/guests`;
export const CREATE_GUEST_ENDPOINT = `${API_BASE_URL}organization/order/create`;
export const GUEST_ORDER_LIST_ENDPOINT = `${API_BASE_URL}organization/order/list`;
export const GUEST_ENDPOINT = `${API_BASE_URL}guest`;
export const ORDER_TICKET_PDF_ENDPOINT = `${API_BASE_URL}order/ticket/generate/`;
