import React, { useEffect, useState } from 'react';
import OrgLayout from './Components/OrgLayout';
import { Link, Navigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import {
  ORGANIZATION_EVENT_ENDPOINT,
  ORGANIZATION_STATS_ENDPOINT,
} from '../../../../../Utils/Endpoints';
import Logger from '../../../../../Utils/Logger';
import moment from 'moment';
import NotFoundPage from '../../Components/NotFoundPage';
import axiosInstance from '../../../../../Utils/axiosInstance';
import { getObject } from '../../../../../Utils/LocalStorage';
import BarCode from './Components/BarCode';
import RevenueChart from './Events/Components/RevenueChart';
import DeleteModal from '../../Components/DeleteModal';
// const data = [
//   { time: '04:30', Revenue: 40, Profit: 30 },
//   { time: '05:00', Revenue: 70, Profit: 35 },
//   { time: '05:30', Revenue: 100, Profit: 41 },
//   { time: '06:00', Revenue: 90, Profit: 40 },
//   { time: '06:15', Revenue: 85, Profit: 38 },
// ];

const OrgDashboard = () => {
  const [upcomingEventList, setUpcomingEventList] = useState([]);
  const [ongoingEventList, setOngoingEventList] = useState([]);
  const [trafficData, setTrafficData] = useState([]);
  const [selectedData] = useState({});
  const [totalItemsUpcoming, setTotalItemsUpcoming] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const selectedOrg = getObject('selectedOrganization');
  const [dashboardDataCount, setDashboardDataCount] = useState({});
  const handleDelete = () => {
    Navigate('/organiziations');
  };

  // const state = {
  //   options: {
  //     chart: {
  //       type: 'area',
  //       height: 350,
  //       zoom: {
  //         enabled: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: 'smooth',
  //     },
  //     xaxis: {
  //       categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  //     },
  //     title: {
  //       text: 'Monthly Sales Data',
  //       align: 'left',
  //     },
  //     fill: {
  //       opacity: 1,
  //       gradient: {
  //         shade: 'light',
  //         type: 'vertical',
  //         shadeIntensity: 0.5,
  //         gradientToColors: ['#ff7c43'], // optional
  //         inverseColors: false,
  //         stops: [0, 100],
  //       },
  //     },
  //     tooltip: {
  //       shared: true,
  //       intersect: false,
  //     },
  //   },
  //   series: [
  //     {
  //       name: 'Sales',
  //       data: [30, 40, 35, 50, 49, 60, 70],
  //     },
  //   ],
  // };

  useEffect(() => {
    fetchDashboardData();
    fetchUpcomingEvent();
    fetchTrafficData();
    fetchOngoingEvent();
  }, []);

  const fetchUpcomingEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_EVENT_ENDPOINT +
          `${selectedOrg.id}/events?event_start_from=${moment().unix()}&per_page=4`
      );
      if (response.data.code === 200) {
        setUpcomingEventList(response.data.data.data);
        setTotalItemsUpcoming(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOngoingEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_EVENT_ENDPOINT + `${selectedOrg.id}/events?on_going=1`
      );
      if (response.data.code === 200) {
        setOngoingEventList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDashboardData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_STATS_ENDPOINT + `${selectedOrg.id}/stats`
      );
      if (response.data.code === 200) {
        setDashboardDataCount(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Views Stats
  const fetchTrafficData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_STATS_ENDPOINT +
          `${selectedOrg.id}/traffic/view/graph?year=2024`
      );
      if (response.data.code === 200) {
        setTrafficData(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const state = {
    options: {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false, // Enable toolbar
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: Object.values(trafficData).map((month) => month.monthName),
      },
      // title: {
      //   text: 'Monthly Views Data',
      //   align: 'left',
      // },
      fill: {
        opacity: 1,
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#ff7c43'],
          inverseColors: false,
          stops: [0, 100],
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    },
    series: [
      {
        name: 'Views',
        data: Object.values(trafficData).map((month) => month.totalViews),
      },
    ],
  };

  return (
    <>
      <OrgLayout>
        <div className="page-breadcrumb d-flex align-items-center mb-3">
          <div className="font-22">Dashboard</div>
          {isLoading && <div className="loader"></div>}
        </div>
        <div className="row row-cols-1 row-cols-lg-4">
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Total Revenue</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-purple">
                    <ion-icon name="wallet-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">
                      {!isLoading && dashboardDataCount?.totalRevenueFormat
                        ? dashboardDataCount?.totalRevenueFormat
                        : '$0.00'}
                    </h4>
                  </div>
                  <div className="ms-auto"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Total Events</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-success">
                    <ion-icon name="bar-chart-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">
                      {!isLoading && dashboardDataCount?.totalEventCount
                        ? dashboardDataCount?.totalEventCount
                        : 0}
                    </h4>
                  </div>
                  <div className="ms-auto"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Tickets Sold</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-danger">
                    <ion-icon name="bag-handle-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">
                      {!isLoading && dashboardDataCount?.totalTicketSold
                        ? dashboardDataCount?.totalTicketSold
                        : 0}
                    </h4>
                  </div>
                  <div className="ms-auto"></div>
                </div>
              </div>
            </div>
          </div>

          <BarCode barCodeData={selectedOrg} type="ORG" />
        </div>
        {ongoingEventList?.length > 0 && (
          <>
            <div className="d-flex align-items-center pb-3">
              {/* <div className="status-text font-22">Ongoing Events</div> */}
              <div>
                <h6 className="status-text">Ongoing Events</h6>
              </div>
              <div className="live-dot"></div>
            </div>
            <div className="row row-cols-1 row-cols-lg-2">
              {ongoingEventList &&
                ongoingEventList?.map((item, index) => (
                  <div className="col" key={index}>
                    <Link to="/edit-events-user" state={{ event: item }}>
                      <div className="card radius-10">
                        <div className="card-body on-going p-0 pe-3">
                          <div className="uu-img">
                            <img
                              src={
                                item?.banner?.fullUrl
                                  ? item?.banner?.fullUrl
                                  : '../images/thumbnail.png'
                              }
                              alt=""
                              style={{
                                borderTopLeftRadius: '8px',
                                borderBottomLeftRadius: '8px',
                              }}
                            />
                          </div>
                          <div className="py-4">
                            <h5 className="mb-0">{item.name}</h5>
                            <p className="mb-0" style={{ fontSize: '13px' }}>
                              {item.startDateTimeFormat}
                            </p>
                          </div>
                          <div className="widget-icon rounded-circle bg-light-info text-info ms-auto">
                            <ion-icon name="create-outline"></ion-icon>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </>
        )}
        <div className="row">
          <div className="col-12 col-lg-8 d-flex">
            <div className="card radius-10 w-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">Earning Reports</h6>
                  <div className="dropdown options ms-auto  d-none">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <RevenueChart
                  dataChart={selectedOrg}
                  URL={ORGANIZATION_STATS_ENDPOINT}
                  revenue={dashboardDataCount?.totalRevenueFormat}
                />

                <div className="d-flex flex-column flex-lg-row align-items-lg-center align-self-end justify-content-lg-between border p-3 gap-3 mb-0 rounded-3">
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-success text-success">
                      <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">
                        {!isLoading && dashboardDataCount?.totalRevenueFormat
                          ? dashboardDataCount?.totalRevenueFormat
                          : '$0.00'}
                      </h4>
                      <p className="mb-0 text-secondary">Total Revenue</p>
                    </div>
                  </div>
                  <div className="vr d-none d-lg-block"></div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-info text-info">
                      <ion-icon name="diamond-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">
                        {!isLoading && dashboardDataCount?.totalProfitFormat
                          ? dashboardDataCount?.totalProfitFormat
                          : '$0.00'}
                      </h4>
                      <p className="mb-0 text-secondary">Total Profit</p>
                    </div>
                  </div>
                  <div className="vr d-none d-lg-block"></div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-purple text-purple">
                      <ion-icon name="people-circle-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">
                        {!isLoading && dashboardDataCount?.totalCustomerCount
                          ? dashboardDataCount?.totalCustomerCount
                          : 0}
                      </h4>
                      <p className="mb-0 text-secondary">Total Customer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card radius-10 overflow-hidden w-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">Total Traffic</h6>
                  <div className="dropdown options ms-auto d-none">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex align-items-center font-13 gap-2">
                  <span className="border px-1 rounded cursor-pointer">
                    <i className="bx bxs-circle me-1 text-tiffany"></i>Views
                  </span>
                  {/* <span className="border px-1 rounded cursor-pointer">
                    <i className="bx bxs-circle me-1 text-success"></i>Shares
                  </span> */}
                </div>
                {/* <div id="chart2"></div> */}
                <Chart
                  options={state.options}
                  series={state.series}
                  type="area"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card p-3">
          <h6>Upcoming Events</h6>
          <hr />
          {!isLoading && upcomingEventList?.length === 0 && (
            <NotFoundPage title="Upcoming Event" isInfo={true} />
          )}
          {!isLoading && upcomingEventList?.length > 0 && (
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-3">
              {upcomingEventList?.map((event, index) => (
                <div className="col" key={index}>
                  <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                    <img
                      src={
                        event?.banner?.fullUrl
                          ? event?.banner?.fullUrl
                          : '../images/thumbnail.png'
                      }
                      className="card-img-top empty"
                      alt="..."
                    />
                    <div className="card-body border-bottom h-100">
                      <h5 className="card-title">{event?.name}</h5>
                      <p className="card-text">
                        {event?.location?.addressString
                          ? event?.location?.addressString
                          : '136 2nd Ave, New York, NY 10003, USA'}
                      </p>
                    </div>

                    <div className="card-body border-top">
                      <Link
                        to="/edit-events-user"
                        state={{ event: event }}
                        className="btn btn-sm btn-outline-warning me-2"
                      >
                        Edit Detail
                      </Link>
                      <span className="float-md-end badge bg-info text-dark d-none">
                        Live
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {totalItemsUpcoming > 4 && (
            <div className="col-md-12 text-align-right mt-2">
              <Link to="/events" className="btn btn-sm btn-outline-info me-2">
                View All
              </Link>
            </div>
          )}
        </div>
      </OrgLayout>
      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={handleDelete}
      />
    </>
  );
};

export default OrgDashboard;
