import React, { useEffect, useState } from 'react';
import OrgLayout from '../Components/OrgLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import Summary from './Components/Summary';
import TicketType from './Components/TicketType';
import PromoCodes from './Components/PromoCodes';
import Guests from './Components/Guests';
import Edit from './Components/Edit';
import Setting from './Components/Setting';
import moment from 'moment';
import EventDeleteModal from './Components/EventDeleteModal';
import { encrypt } from '../../../../../../Utils/common';
import axiosInstance from '../../../../../../Utils/axiosInstance';
import Logger from '../../../../../../Utils/Logger';
import { QR_CODE_ENDPOINT } from '../../../../../../Utils/Endpoints';
import jsQR from 'jsqr';

const EditEventsUser = () => {
  const location = useLocation();
  const eventObject = location.state && location.state.event;
  const [selectedData, setSelectedData] = useState({});
  const [activeTab, setActiveTab] = useState('warning-pills-home');
  const [isLoading, setIsLoading] = useState(false);
  const [imageQRCode, setImageQRCode] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchQRCode();
  }, []);

  const fetchQRCode = async () => {
    try {
      setIsLoading(true);
      const code = encrypt('VIEW', eventObject?.id);
      const url = process.env.REACT_APP_FRONT_URL + code;
      const response = await axiosInstance.get(
        QR_CODE_ENDPOINT + `?data=${url}`
      );
      if (response.status === 200) {
        setImageQRCode(response.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenLink = () => {
    // Create an Image element
    const img = new Image();
    img.src = imageQRCode;

    img.onload = () => {
      // Create a canvas to draw the image
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      context.drawImage(img, 0, 0);

      // Get the image data from the canvas
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

      // Scan the QR code
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        // Open the scanned link in a new tab
        window.open(code.data, '_blank');
      } else {
        Logger.log('No QR code found!');
      }
    };
  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleDelete = () => {
    navigate('/events');
  };

  return (
    <>
      <OrgLayout>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4>{eventObject?.name}</h4>
            <p>
              {moment
                .unix(eventObject?.start_date_time_unix)
                .format('MMMM D, YYYY hh:mm A ')}
              • {eventObject?.location?.city?.name}
            </p>
          </div>
          <div className="d-flex gap-2 icon-set-in-top bbb ddd-2">
            {!isLoading && (
              <div
                className="d-flex align-items-center gap-2 "
                onClick={handleOpenLink}
                style={{ cursor: 'pointer' }}
              >
                <div className="widget-icon rounded-circle bg-light-info text-info">
                  <ion-icon name="open-outline"></ion-icon>
                </div>
                <p className="text-info">Preview Page</p>
              </div>
            )}
            <div
              className="d-flex align-items-center gap-2"
              style={{ cursor: 'pointer' }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModalEvent"
              onClick={() =>
                setSelectedData({
                  id: eventObject?.id,
                  parentId: eventObject?.organization_id,
                  type: 'event',
                })
              }
            >
              <div className="widget-icon rounded-circle bg-light-danger text-danger">
                <ion-icon
                  name="trash-outline"
                  role="img"
                  className="md hydrated"
                ></ion-icon>
              </div>
              <p className="text-danger">Event Delete</p>
            </div>
          </div>
          <div className="d-flex gap-2 icon-set-in-top ddd bbb-2">
            {!isLoading && (
              <div
                className="d-flex align-items-center gap-2 "
                onClick={handleOpenLink}
                style={{ cursor: 'pointer' }}
              >
                <div className="widget-icon rounded-circle bg-light-info text-info">
                  <ion-icon name="open-outline"></ion-icon>
                </div>
              </div>
            )}
            <div
              className="d-flex align-items-center gap-2"
              style={{ cursor: 'pointer' }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModalEvent"
              onClick={() =>
                setSelectedData({
                  id: eventObject?.id,
                  parentId: eventObject?.organization_id,
                  type: 'event',
                })
              }
            >
              <div className="widget-icon rounded-circle bg-light-danger text-danger">
                <ion-icon
                  name="trash-outline"
                  role="img"
                  className="md hydrated"
                ></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <ul
            className="mb-3 nav nav-tabs nav-warning new-tab-mobile"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-home' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-home')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="newspaper-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="home sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Summary</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-profile' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-profile')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="ticket-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="person sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Ticket Types</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-contact' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-contact')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="color-palette-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Promo Codes</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-guests' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-guests')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="people-circle-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Guests</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-edit' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-edit')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="pencil-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Edit</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-setting' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-setting')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="settings-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Setting</div>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {activeTab === 'warning-pills-home' && (
              <Summary event={eventObject} />
            )}

            {activeTab === 'warning-pills-profile' && (
              <TicketType event={eventObject} />
            )}

            {activeTab === 'warning-pills-contact' && (
              <PromoCodes event={eventObject} />
            )}
            {activeTab === 'warning-pills-guests' && (
              <Guests event={eventObject} />
            )}
            {activeTab === 'warning-pills-edit' && <Edit event={eventObject} />}
            {activeTab === 'warning-pills-setting' && (
              <Setting event={eventObject} />
            )}
          </div>
        </div>
        <EventDeleteModal
          selectedData={selectedData}
          handleUpdateList={handleDelete}
        />
      </OrgLayout>
    </>
  );
};

export default EditEventsUser;
