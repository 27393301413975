import { useEffect, useState } from 'react';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import { GUEST_ENDPOINT } from '../../../../../../../Utils/Endpoints';
import Toast from '../../../../../../../Utils/Toast';
import PropTypes from 'prop-types';

const NewGuestForm = ({ selectedGuest, handleClose, fetchGuest }) => {
  // Guest Form
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');

  useEffect(() => {
    if (selectedGuest?.id) {
      setFirstName(selectedGuest.first_name);
      setLastName(selectedGuest.last_name);
      setEmail(selectedGuest.email);
      setDob(selectedGuest.dob);
      setGender(selectedGuest.gender);
    } else {
      handleClear();
    }
  }, [selectedGuest]);

  const handleGuest = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      const requestObject = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        dob: dob,
        gender: gender,
      };

      const response = selectedGuest?.id
        ? await axiosInstance.patch(
            GUEST_ENDPOINT + `/${selectedGuest?.id}`,
            requestObject
          )
        : await axiosInstance.post(GUEST_ENDPOINT, requestObject);
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      await handleClear();
      await handleClose();
      await fetchGuest();
      Toast.success(response.data.message, {
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setDob('');
    setGender('');
    setGeneralError('');
  };

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleGuest}>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label htmlFor="Name" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label htmlFor="email" className="form-label">
                Guest Email
              </label>
              <input
                type="email"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled={selectedGuest?.id}
                required
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label htmlFor="dob" className="form-label">
                DOB
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => setDob(e.target.value)}
                value={dob}
                required
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="gender" className="form-label">
                Gender
              </label>
              <select
                id="gender"
                className="form-select"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
                required
              >
                <option value="">Select Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">other</option>
              </select>
            </div>
            <div className="col-12 mt-4">
              <div className="d-flex align-items-center gap-3">
                <button className="btn btn-outline-warning px-4" type="submit">
                  {isLoading
                    ? 'Loading...'
                    : selectedGuest?.id
                      ? 'Update'
                      : 'Save'}
                </button>
                <button
                  className="btn btn-outline-info px-4"
                  type="button"
                  onClick={() => {
                    handleClear();
                    handleClose();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
        {generalError && (
          <div className="error text-center">{generalError}</div>
        )}
      </div>
    </div>
  );
};

NewGuestForm.propTypes = {
  selectedGuest: PropTypes.object,
  handleClose: PropTypes.func,
  fetchGuest: PropTypes.func,
};

export default NewGuestForm;
