import Login from '../Seller/Pages/Auth/Login/Login';
import Forgot from '../Seller/Pages/Auth/PasswordForgot/Forgot';
import Signup from '../Seller/Pages/Auth/Signup/Signup';
// import Home from '../Seller/Pages/Home/Home';
import HomeCardPreview from '../Seller/Pages/Home/HomeCardPreview.jsx';

// Public Routes
export const publicRoutes = [
  { path: '/', element: <Login />, redirectTo: '/dashboard' },
  { path: 'login', element: <Login />, redirectTo: '/dashboard' },
  { path: 'signup', element: <Signup />, redirectTo: '/dashboard' },
  { path: 'forgot', element: <Forgot />, redirectTo: '/dashboard' },
  { path: 'home-card-preview', element: <HomeCardPreview /> },
];
