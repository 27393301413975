import React, { useEffect, useRef, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import Stepper from 'bs-stepper';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  ORGANIZATION_ENDPOINT,
  ORGANIZATION_EVENT_ENDPOINT,
} from '../../../../Utils/Endpoints';
import Logger from '../../../../Utils/Logger';
import moment from 'moment';
import AddressForm from '../../../Components/AddressForm';
import { uploadFile } from '../../../../Utils/upload';
import { EVENT_CLASS } from '../../../../Utils/ModelClasses';
import { EVENT_BANNER_TYPE } from '../../../../Utils/UploadType';
import { getObject } from '../../../../Utils/LocalStorage';
import DateTimePicker from '../../../Components/DateTimePicker';

const CreateEventForm = () => {
  const [locationType, setLocationType] = useState('SPECIFIC_LOCATION'); // Default to SPECIFIC_LOCATION
  const [currentStep, setCurrentStep] = useState(1);
  const [eventName, setEventName] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const location = useLocation();
  const selectedOrg = getObject('selectedOrganization');
  const [selectedOrganiziation, setSelectedOrganiziation] = useState(
    selectedOrg && location.pathname.includes('org-event-create')
      ? selectedOrg?.id
      : ''
  );
  const [description, setDescription] = useState('');
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [locationName, setLocationName] = useState('');
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [organiziation, setOrganiziation] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const formRef1 = useRef(null);
  const formRef2 = useRef(null);
  const formRef3 = useRef(null);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [eventID, setEventID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [isOrgAvailable, setIsOrgAvailable] = useState(null);
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  useEffect(() => {
    setIsOrgAvailable(null);
    fetchOrganiziation();
  }, []);

  const fetchOrganiziation = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(ORGANIZATION_ENDPOINT);
      if (response.data.code === 200) {
        setOrganiziation(response.data.data.data);
        setIsOrgAvailable(response.data.data.data?.length === 0 ? true : false);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const stepperRef = useRef(null);
  useEffect(() => {
    if (stepperRef.current) {
      // Initialize your stepper here
      new Stepper(stepperRef.current);
    }
  }, [stepperRef]);

  const handleNextStep = (e) => {
    e.preventDefault();
    const form =
      currentStep === 1
        ? formRef1.current
        : currentStep === 2
          ? formRef2.current
          : formRef3.current;
    if (form.checkValidity()) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      form.reportValidity();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);

    try {
      let responseId;
      if (!eventID) {
        const requestObject = {
          name: eventName,
          event_url: null,
          location_type: locationType,
          door_open_date_time_unix: '',
          start_date_time_unix: moment(startDateTime).unix(),
          end_date_time_unix: moment(endDateTime).unix(),
          minimum_age: '',
          description: description,
          accent_color: '',
          duration: '',
          hide_after_expiration: false,
          is_active: 1,
          is_featured: false,
          entity_has_address: {
            title: locationName,
            address_1:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.address_1
                : '',
            address_2:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.address_2
                : '',
            city_id: selectedCity?.city_id,
            state_id: selectedCity?.state_id,
            country_id: selectedCity?.country_id,
            zip_code:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.zip_code
                : '',
            lat:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.lat
                : null,
            lng:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.lng
                : null,
            contact_number: locationType === 'SPECIFIC_LOCATION' ? null : null,
            contact_person_name:
              locationType === 'SPECIFIC_LOCATION' ? null : null,
          },
        };
        const response = await axiosInstance.post(
          ORGANIZATION_EVENT_ENDPOINT + `${selectedOrganiziation}/event`,
          requestObject
        );
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseId = response.data.data.id;
        setEventID(response.data.data.id);
      }

      if (image && !imageID) {
        try {
          const imageData = await uploadFile(
            EVENT_CLASS,
            responseId ? responseId : eventID,
            EVENT_BANNER_TYPE,
            image
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }
      await handleNavigation(
        location.pathname.includes('org-event-create') ? '/events' : '/listing'
      );
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="page-breadcrumb d-flex align-items-center mb-3">
        <div className="font-22">Create New Event</div>
        <Link
          to={
            location.pathname.includes('org-event-create')
              ? '/events'
              : '/listing'
          }
          className="btn btn-outline-secondary ms-auto"
        >
          <i className="bx bx-left-arrow-alt"></i> Back
        </Link>
      </div>

      {!isLoading && organiziation?.length > 0 && (
        <div id="stepper1" className="bs-stepper" ref={stepperRef}>
          <div className="card">
            <div className="card-header pe-md-5">
              <div
                className="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between tab-ttt"
                role="tablist"
              >
                <div
                  className={`step ${currentStep === 1 ? 'active' : ''}`}
                  data-target="#test-l-1"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger1"
                    aria-controls="test-l-1"
                  >
                    <div className="bs-stepper-circle">1</div>
                    <div className="s-v">
                      <h5 className="mb-0 steper-title">The Basics</h5>
                      <p className="mb-0 steper-sub-title">Step 1</p>
                    </div>
                  </div>
                </div>
                <div className="bs-stepper-line"></div>
                <div
                  className={`step ${currentStep === 2 ? 'active' : ''}`}
                  data-target="#test-l-2"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger2"
                    aria-controls="test-l-2"
                  >
                    <div className="bs-stepper-circle">2</div>
                    <div className="s-v">
                      <h5 className="mb-0 steper-title">A Little Bit More</h5>
                      <p className="mb-0 steper-sub-title">Step 2</p>
                    </div>
                  </div>
                </div>
                <div className="bs-stepper-line"></div>
                <div
                  className={`step ${currentStep === 3 ? 'active' : ''}`}
                  data-target="#test-l-3"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger3"
                    aria-controls="test-l-3"
                  >
                    <div className="bs-stepper-circle">3</div>
                    <div className="s-v">
                      <h5 className="mb-0 steper-title">Visual Appearance</h5>
                      <p className="mb-0 steper-sub-title">Step 3</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="bs-stepper-content">
                {/* <form ref={formRef1}> */}
                <div
                  id="test-l-1"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 1 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger1"
                >
                  <h5 className="mb-1">Let&apos;s get down to the details</h5>
                  <p className="mb-4"></p>
                  <form ref={formRef1} noValidate>
                    <div className="row g-3">
                      {/* Conditionally render organization fields */}
                      {!location.pathname.includes('org-event-create') && (
                        <div className="col-12">
                          <label
                            htmlFor="organizationName"
                            className="form-label"
                          >
                            Which organization do you want this event to be a
                            part of?
                          </label>
                          <select
                            className="form-control"
                            id="organizationName"
                            required
                            disabled={isLoading || organiziation?.length === 0}
                            value={selectedOrganiziation}
                            onChange={(e) =>
                              setSelectedOrganiziation(e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select Organiziation*
                            </option>
                            {organiziation.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="col-12">
                        <label htmlFor="EventName" className="form-label">
                          What is the name of your event?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="EventName"
                          value={eventName}
                          required
                          onChange={(e) => setEventName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          htmlFor="EventStartDateTime"
                          className="form-label"
                        >
                          When does the event start?
                        </label>
                        <DateTimePicker
                          value={startDateTime}
                          onChange={setStartDateTime}
                          minDate={new Date()}
                        />
                        {/* <input
                          type="datetime-local"
                          className="form-control"
                          id="EventStartDateTime"
                          value={startDateTime}
                          required
                          min={new Date().toISOString().slice(0, 16)}
                          onChange={(e) => setStartDateTime(e.target.value)}
                        /> */}
                      </div>
                      <div className="col-lg-6">
                        <label
                          htmlFor="EventEndDateTime"
                          className="form-label"
                        >
                          And what time does it end?
                        </label>
                        <DateTimePicker
                          value={endDateTime}
                          onChange={setEndDateTime}
                          minDate={new Date()}
                        />
                        {/* <input
                          type="datetime-local"
                          className="form-control"
                          id="EventEndDateTime"
                          value={endDateTime}
                          required
                          min={new Date().toISOString().slice(0, 16)}
                          onChange={(e) => setEndDateTime(e.target.value)}
                        /> */}
                      </div>
                      <div className="col-12">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="description"
                          value={description}
                          required
                          rows={3}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-outline-warning px-4"
                          onClick={handleNextStep}
                          type="button"
                        >
                          Next<i className="bx bx-right-arrow-alt ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  id="test-l-2"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 2 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger2"
                >
                  <h5 className="mb-1">Where does the event take place?</h5>
                  <p className="mb-4">
                    If there&lsquo;s no single place, just select the most
                    common city.
                  </p>
                  <form ref={formRef2} noValidate>
                    <AddressForm
                      handlePreviousStep={handlePreviousStep}
                      handleNextStep={handleNextStep}
                      locationType={locationType}
                      setLocationType={setLocationType}
                      setSelectedAddress={setSelectedAddress}
                      selectedAddress={selectedAddress}
                      setSelectedCity={setSelectedCity}
                      locationName={locationName}
                      setLocationName={setLocationName}
                      city={city}
                      setCity={setCity}
                      address={address}
                      setAddress={setAddress}
                    />
                  </form>
                </div>
                <div
                  id="test-l-3"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 3 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger3"
                >
                  <h5 className="mb-1">
                    Got an event flyer? You can drop it here!
                  </h5>
                  <p className="mb-4">Give your event its unique touch.</p>
                  <form ref={formRef3} noValidate>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="card bg-secondary-dark p-5">
                          {/* <input
                     type="file"
                     className="form-control"
                     id="eventFlyer"
                     onChange={(e) => {
                       const image = e.target.files[0];
                       const reader = new FileReader();
                       reader.onload = () => {
                         const imageDataUrl = reader.result;
                         setImage(imageDataUrl);
                       };
                       reader.readAsDataURL(image);
                     }}
                   /> */}
                          <input
                            type="file"
                            className="form-control"
                            id="eventFlyer"
                            required
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3">
                          <button
                            className="btn btn-outline-secondary px-4"
                            onClick={handlePreviousStep}
                            type="button"
                          >
                            <i className="bx bx-left-arrow-alt me-2"></i>
                            Previous
                          </button>
                          <button
                            className="btn bg-gradient-branding text-dark px-4"
                            onClick={handleSubmit}
                            type="button"
                            disabled={isLoading}
                          >
                            {isLoading ? 'Loading...' : 'Publish Event'}
                            <i className="bx bx-right-arrow-alt ms-2"></i>
                          </button>
                        </div>
                        {generalError && (
                          <div className="error text-center">
                            {generalError}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLoading && organiziation?.length === 0 && isOrgAvailable && (
        <div className="card ">
          <div className="text-center mt-5 mb-3 text-danger fw-bolder fs-5 bg-white p-3 rounded-3 m-5">
            Please ensure that an organization is created before proceeding with
            event creation.
          </div>
          <Link
            to="/create-org"
            className="btn btn-outline-info ms-auto me-auto mb-5"
          >
            <i className="bx bx-plus"></i>
            Create Organization
          </Link>
        </div>
      )}
    </>
  );
};

export default CreateEventForm;
