import React, { useEffect, useState } from 'react';
import Logger from '../../../../../../../Utils/Logger';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import Toast from '../../../../../../../Utils/Toast';
import { PROMOCODE_ENDPOINT } from '../../../../../../../Utils/Endpoints';
import PropTypes from 'prop-types';
import NotFoundPage from '../../../../Components/NotFoundPage';
import AddPromoCode from './AddPromoCode';
import { copyToClipboard } from '../../../../../../../Utils/common';

const PromoCodes = ({ event }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState({});

  const handleAdd = () => {
    setIsAdd(!isAdd);
    setSelectedPromoCode({});
  };

  const handleEdit = (item) => {
    setSelectedPromoCode(item);
    setIsAdd(!isAdd);
  };

  useEffect(() => {
    fetchPromoCode();
  }, []);

  const fetchPromoCode = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        PROMOCODE_ENDPOINT + `${event.id}/list`
      );
      if (response.data.code === 200) {
        setPromoCodeList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault(id);
    try {
      setIsLoading(true);
      const response = await axiosInstance.delete(
        PROMOCODE_ENDPOINT + `${event.id}/${id}`
      );
      if (response.status === 204) {
        await fetchPromoCode();
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      Toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="page-breadcrumb d-flex align-items-center mb-3">
        <div className="font-22">Promo Codes</div>
        {!isAdd && (
          <a className="btn btn-outline-info ms-auto" onClick={handleAdd}>
            <i className="bx bx-plus"></i>ADD
          </a>
        )}
        {isAdd && (
          <a className="btn btn-outline-secondary ms-auto" onClick={handleAdd}>
            <i className="bx bx-left-arrow-alt"></i> Back
          </a>
        )}
      </div>
      {!isAdd && (
        <>
          {!isLoading && promoCodeList?.length > 0 && (
            <div className="card">
              <div className="card-body">
                <div className="table-responsive mt-3">
                  <table className="table align-middle table-s-c t-w-s-2 pp-mo">
                    <thead className="table-light">
                      <tr>
                        <th>Sr.No</th>
                        <th>Discount</th>
                        <th>Code</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promoCodeList?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="d-flex align-items-center gap-2 justify-content-center">
                              <p className="mb-0">{item?.discount_amount}%</p>
                            </div>
                          </td>
                          <td>
                            <div
                              className="d-flex justify-content-center align-items-center gap-2"
                              style={{ cursor: 'pointer' }}
                            >
                              <p>{item.code}</p>
                              <ion-icon
                                name="copy-outline"
                                onClick={() =>
                                  copyToClipboard(item.code, 'Code')
                                }
                              ></ion-icon>
                            </div>
                          </td>
                          <td>
                            <div className="table-actions d-flex align-items-center justify-content-center gap-2 fs-6">
                              <div
                                className="widget-icon rounded-circle bg-light-info text-info"
                                onClick={() => handleEdit(item)}
                              >
                                <ion-icon
                                  name="create-outline"
                                  role="img"
                                  class="md hydrated"
                                ></ion-icon>
                              </div>
                              <div className="widget-icon rounded-circle bg-light-purple text-purple d-none">
                                <ion-icon
                                  name="copy-outline"
                                  role="img"
                                  class="md hydrated"
                                ></ion-icon>
                              </div>
                              <div
                                className="widget-icon rounded-circle bg-light-danger text-danger"
                                onClick={(e) => handleDelete(e, item.id)}
                              >
                                <ion-icon
                                  name="trash-outline"
                                  role="img"
                                  class="md hydrated"
                                ></ion-icon>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                      <td>
                        <div className="d-flex align-items-center gap-2 justify-content-center">
                          <ion-icon name="speedometer-outline"></ion-icon>
                          <p className="mb-0">-2</p>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <p>AA7TCUG</p>
                          <ion-icon name="copy-outline"></ion-icon>
                        </div>
                      </td>
                      <td>
                        <p>Never Used</p>
                      </td>
                      <td>
                        <div className="table-actions d-flex align-items-center justify-content-center gap-3 fs-6">
                          <div className="widget-icon rounded-circle bg-light-purple text-purple">
                            <ion-icon
                              name="create-outline"
                              role="img"
                              class="md hydrated"
                            ></ion-icon>
                          </div>
                          <div className="widget-icon rounded-circle bg-light-purple text-purple">
                            <ion-icon
                              name="copy-outline"
                              role="img"
                              class="md hydrated"
                            ></ion-icon>
                          </div>
                          <div className="widget-icon rounded-circle bg-light-purple text-purple">
                            <ion-icon
                              name="trash-outline"
                              role="img"
                              class="md hydrated"
                            ></ion-icon>
                          </div>
                        </div>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {!isAdd && (
        <>
          {!isLoading && promoCodeList?.length === 0 && (
            <NotFoundPage title="Promo Code" />
          )}
        </>
      )}
      {isAdd && (
        <AddPromoCode
          event={event}
          handleAdd={handleAdd}
          fetchPromoCode={fetchPromoCode}
          selectedPromoCode={selectedPromoCode}
        />
      )}
    </>
  );
};

PromoCodes.propTypes = {
  event: PropTypes.object,
};
export default PromoCodes;
