import { useEffect, useState } from 'react';
import Logger from '../../../../../../../Utils/Logger';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import {
  CREATE_GUEST_ENDPOINT,
  EVENT_TICKET_ENDPOINT,
  USER_LIST_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import Multiselect from 'multiselect-react-dropdown';
import { TICKET_CLASS } from '../../../../../../../Utils/ModelClasses';
import PropTypes from 'prop-types';
import Toast from '../../../../../../../Utils/Toast';

const AddGuest = ({ event, fetchGuest, handleAdd }) => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddGuest, setIsAddGuest] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [generalError, setGeneralError] = useState('');
  const [ticketList, setTicketList] = useState([]);
  const [selectedTicketIds, setSelectedTicketIds] = useState([]);

  useEffect(() => {
    fetchUser();
  }, [searchText]);

  useEffect(() => {
    fetchTicket();
  }, []);

  const fetchUser = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        USER_LIST_ENDPOINT + `?search=${searchText}`
      );
      if (response.data.code === 200) {
        setUserList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTicket = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${event?.id}/tickets`
      );
      if (response.data.code === 200) {
        setTicketList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelect = (option) => {
    setSelectedIds(option);
  };

  const onRemove = (option) => {
    setSelectedIds(option);
  };

  const handleAddGuest = () => {
    setIsAddGuest(!isAddGuest);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      const filteredArray = selectedIds.map((item) => item.id);
      const filteredTicketArray = selectedTicketIds.map((item) => item.id);
      if (filteredTicketArray?.length > 0 && filteredArray?.length > 0) {
        const requestObject = {
          items: [
            {
              owner_class: TICKET_CLASS,
              owner_id: filteredTicketArray && filteredTicketArray[0],
              quantity: quantity,
            },
          ],
          user_id: filteredArray && filteredArray[0],
        };

        const response = await axiosInstance.post(
          CREATE_GUEST_ENDPOINT,
          requestObject
        );
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        await handleClear();
        await handleAdd();
        await fetchGuest();
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError('Please select at least one ticket and one guest');
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setGeneralError('');
    handleAddGuest();
    setSelectedIds([]);
    setSelectedTicketIds([]);
    setQuantity(1);
  };

  return (
    <div className="card">
      <div className="card-body">
        {!isAddGuest && (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="organizationName" className="form-label">
                  Guest*
                </label>
                <Multiselect
                  isObject={true}
                  options={userList}
                  loading={isLoading}
                  selectedValues={selectedIds}
                  customCloseIcon={
                    <>
                      <img src="../images/cross-icon.png" alt="" />
                    </>
                  }
                  className={selectedIds?.length > 0 ? 'multi' : ''}
                  displayValue="email"
                  placeholder="Select Guest"
                  // selectionLimit={1}
                  required
                  onSelect={onSelect}
                  onRemove={onRemove}
                  onSearch={(text) => setSearchText(text)}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="tickets" className="form-label mt-3 mt-lg-0">
                  Choose Ticket*
                </label>
                <Multiselect
                  required
                  isObject={true}
                  disabled={ticketList?.length === 0}
                  customCloseIcon={
                    <>
                      <img src="../images/cross-icon.png" alt="" />
                    </>
                  }
                  options={ticketList}
                  selectedValues={selectedTicketIds}
                  onSelect={(ticket) => setSelectedTicketIds(ticket)}
                  onRemove={(ticket) => setSelectedTicketIds(ticket)}
                  displayValue="name"
                  className={selectedTicketIds?.length > 0 ? 'multi' : ''}
                  id="tickets"
                  selectionLimit={1}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="quantity" className="form-label mt-3 mt-lg-0">
                  Choose Quantity
                </label>
                <select
                  id="quantity"
                  className="form-select"
                  value={quantity}
                  required
                  onChange={(e) => setQuantity(e.target.value)}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>
              <div className="col-12 mt-4">
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn btn-outline-warning px-4"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
        {generalError && (
          <div className="error text-center">{generalError}</div>
        )}
      </div>
    </div>
  );
};

AddGuest.propTypes = {
  event: PropTypes.object,
  fetchGuest: PropTypes.func,
  handleAdd: PropTypes.func,
};

export default AddGuest;
