import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLoginSeller, logoutSeller } from '../../../../Utils/LocalStorage';

const TopNav = () => {
  const navigate = useNavigate();
  const user = getLoginSeller();

  useEffect(() => {
    if (
      user &&
      !user.first_name &&
      window.location.pathname !== '/profile-update'
    ) {
      navigate('/profile-update');
    }
  }, [user]);

  const handleNavigation = (url) => {
    navigate(url);
  };

  const handleSignOut = async () => {
    await logoutSeller();
    await handleNavigation('/login');
  };

  const handleMenuCollaps = () => {
    const wrapp = document.getElementsByClassName('wrapper');
    // Convert HTMLCollection to an array and iterate over it
    Array.from(wrapp).forEach((element) => {
      element.classList.toggle('toggled'); // Add or remove the 'toggled' class
    });
  };

  return (
    <>
      <header className="top-header">
        <nav className="navbar navbar-expand gap-3">
          <div className="toggle-icon" onClick={handleMenuCollaps}>
            <ion-icon name="menu-outline"></ion-icon>
          </div>
          <form className="searchbar d-none">
            <div className="position-absolute top-50 translate-middle-y search-icon ms-3">
              <ion-icon name="search-outline"></ion-icon>
            </div>
            <input
              className="form-control"
              type="text"
              placeholder="Search for anything"
            />
            <div className="position-absolute top-50 translate-middle-y search-close-icon">
              <ion-icon name="close-outline"></ion-icon>
            </div>
          </form>
          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item d-none">
                <a className="nav-link mobile-search-button" href="">
                  <div className="">
                    <ion-icon name="search-outline"></ion-icon>
                  </div>
                </a>
              </li>
              <li className="nav-item dropdown dropdown-large d-none">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret "
                  href=""
                  data-bs-toggle="dropdown"
                >
                  <div className="position-relative">
                    <span className="notify-badge">8</span>
                    <ion-icon name="notifications-outline"></ion-icon>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a href="">
                    <div className="msg-header">
                      <p className="msg-header-title">Notifications</p>
                      <p className="msg-header-clear ms-auto">
                        Marks all as read
                      </p>
                    </div>
                  </a>
                  <div className="header-notifications-list">
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-primary">
                          <ion-icon name="cart-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Orders{' '}
                            <span className="msg-time float-end">
                              2 min ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            You have recived new orders
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-danger">
                          <ion-icon name="person-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Customers
                            <span className="msg-time float-end">
                              14 Sec ago
                            </span>
                          </h6>
                          <p className="msg-info">5 new user registered</p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-success">
                          <ion-icon name="document-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            24 PDF File
                            <span className="msg-time float-end">
                              19 min ago
                            </span>
                          </h6>
                          <p className="msg-info">The pdf files generated</p>
                        </div>
                      </div>
                    </a>

                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-info">
                          <ion-icon name="checkmark-done-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Product Approved{' '}
                            <span className="msg-time float-end">
                              2 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            Your new product has approved
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-warning">
                          <ion-icon name="send-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Time Response{' '}
                            <span className="msg-time float-end">
                              28 min ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            5.1 min avarage time response
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-danger">
                          <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Comments{' '}
                            <span className="msg-time float-end">
                              4 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            New customer comments recived
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-primary">
                          <ion-icon name="albums-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New 24 authors
                            <span className="msg-time float-end">
                              1 day ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            24 new authors joined last week
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-success">
                          <ion-icon name="shield-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Your item is shipped{' '}
                            <span className="msg-time float-end">
                              5 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            Successfully shipped your item
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="">
                      <div className="d-flex align-items-center">
                        <div className="notify text-warning">
                          <ion-icon name="cafe-outline"></ion-icon>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Defense Alerts{' '}
                            <span className="msg-time float-end">
                              2 weeks ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            45% less alerts last 4 weeks
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <a href="">
                    <div className="text-center msg-footer">
                      View All Notifications
                    </div>
                  </a>
                </div>
              </li>
              <li className="nav-item dropdown dropdown-user-setting">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  href=""
                  data-bs-toggle="dropdown"
                >
                  <div className="user-setting">
                    <img
                      src={
                        user?.profile_picture && user?.profile_picture?.fullUrl
                          ? user?.profile_picture?.fullUrl
                          : '../images/profile.png'
                      }
                      className="user-img"
                      alt=""
                    />
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end d-m-e">
                  <li>
                    <a className="dropdown-item">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <div className="user-login-image">
                          <img
                            src={
                              user?.profile_picture &&
                              user?.profile_picture?.fullUrl
                                ? user?.profile_picture?.fullUrl
                                : '../images/profile.png'
                            }
                            alt=""
                            className="rounded-circle"
                          />
                        </div>
                        <div className="">
                          <h6 className="mb-0 dropdown-user-name">
                            {user?.name}
                          </h6>
                          <small className="mb-0 dropdown-user-designation text-secondary">
                            UI Developer
                          </small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/profile-update">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <ion-icon name="person-outline"></ion-icon>
                        </div>
                        <div className="ms-3">
                          <span>My Account</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleSignOut}>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <ion-icon name="log-out-outline"></ion-icon>
                        </div>
                        <div className="ms-3">
                          <span>Logout</span>
                        </div>
                      </div>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default TopNav;
