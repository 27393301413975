import Logger from './Logger';
import Toast from './Toast';

/* eslint-disable no-useless-escape */
export const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const emailRegExp =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

/* eslint-enable no-useless-escape */

export const downloadBase64Image = (base64Data, filename) => {
  // Create a link element
  const link = document.createElement('a');

  // Set the href attribute to the Base64 string
  link.href = base64Data;

  // Set the download attribute with the desired filename
  link.download = filename;

  // Append the link to the body (necessary for Firefox)
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

export const encrypt = (type, id) => {
  const combined = `${type}${id}`;
  // Simple encoding logic (you can replace this with a better algorithm)
  let encoded = btoa(combined);
  // Return the first 6 characters of the encoded string
  return encoded.substring(0, 10);
};

// export const copyToClipboard = (data) => {
//   navigator.clipboard
//     .writeText(data)
//     .then(() => {
//       Logger.log('Scanned data copied to clipboard!');
//     })
//     .catch((err) => {
//       Logger.error('Failed to copy: ', err);
//     });
// };

// export const copyToClipboard = (text) => {
//   // Create a temporary textarea element to hold the text
//   const textarea = document.createElement('textarea');
//   textarea.value = text;

//   // Make the textarea element non-visible
//   textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
//   textarea.style.opacity = '0';

//   // Append the textarea to the body
//   document.body.appendChild(textarea);

//   // Select the text in the textarea
//   textarea.select();
//   textarea.setSelectionRange(0, 99999); // For mobile devices

//   // Execute the copy command
//   try {
//     const successful = document.execCommand('copy');
//     if (successful) {
//       Toast.success('Link Copied Successfully!', {
//         autoClose: 2000,
//       });
//       Logger.log('Scanned data copied to clipboard!');
//     } else {
//       Logger.error('Failed to copy.');
//     }
//   } catch (err) {
//     Logger.error('Failed to copy: ', err);
//   }

//   // Remove the temporary textarea element
//   document.body.removeChild(textarea);
// };
export const copyToClipboard = async (text, message) => {
  if (navigator.clipboard && window.isSecureContext) {
    // Use the Clipboard API if available
    try {
      console.log(text, message, 'Copy data');
      await navigator.clipboard.writeText(`${text}`);
      Toast.success(`${message} Copied Successfully!`, {
        autoClose: 2000,
      });
      Logger.log('Scanned data copied to clipboard!');
    } catch (err) {
      Logger.error('Failed to copy using Clipboard API: ', err);
      fallbackCopyToClipboard(text); // Fallback to execCommand if it fails
    }
  } else {
    // Fallback to the execCommand method
    fallbackCopyToClipboard(text);
  }
};

const fallbackCopyToClipboard = (text) => {
  // Create a temporary textarea element to hold the text
  const textarea = document.createElement('textarea');
  textarea.value = text;

  // Make the textarea element non-visible
  textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
  textarea.style.opacity = '0';

  // Append the textarea to the body
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();
  textarea.setSelectionRange(0, 99999); // For mobile devices

  // Execute the copy command
  try {
    const successful = document.execCommand('copy');
    if (successful) {
      Toast.success('Link Copied Successfully!', {
        autoClose: 2000,
      });
      Logger.log('Scanned data copied to clipboard!');
    } else {
      Toast.error('Failed to copy', {
        autoClose: 2000,
      });
      Logger.error('Failed to copy using execCommand.');
    }
  } catch (err) {
    Toast.error('Failed to copy', {
      autoClose: 2000,
    });
    Logger.error('Failed to copy using execCommand: ', err);
  }

  // Remove the temporary textarea element
  document.body.removeChild(textarea);
};
