import React, { useEffect, useRef, useState } from 'react';
import Stepper from 'bs-stepper';
import { useNavigate } from 'react-router-dom';
import AddressForm from '../../../../../../Components/AddressForm';
import moment from 'moment';
import { EVENT_BANNER_TYPE } from '../../../../../../../Utils/UploadType';
import { EVENT_CLASS } from '../../../../../../../Utils/ModelClasses';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import { uploadFile } from '../../../../../../../Utils/upload';
import PropTypes from 'prop-types';
import {
  ORGANIZATION_EVENT_ENDPOINT,
  REMOVE_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import { getObject } from '../../../../../../../Utils/LocalStorage';
import Toast from '../../../../../../../Utils/Toast';
import SocialMedia from './SocialMedia';
import ChooseTag from './ChooseTag';
import DateTimePicker from '../../../../../../Components/DateTimePicker';

const Edit = ({ event }) => {
  //  new form js start
  const [currentStep, setCurrentStep] = useState(1);
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [locationName, setLocationName] = useState('');
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [preview, setPreview] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('general'); // Default active tab

  const stepperRef = useRef(null);
  const [locationType, setLocationType] = useState('SPECIFIC_LOCATION'); // Default to SPECIFIC_LOCATION
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [eventID, setEventID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const selectedOrg = getObject('selectedOrganization');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const formRef1 = useRef(null);
  const formRef2 = useRef(null);
  const formRef3 = useRef(null);
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Update the active tab state
    setCurrentStep(1); // Reset step if needed when changing tabs
    handleClearField();
  };

  useEffect(() => {
    if (stepperRef.current) {
      // Initialize your stepper here
      new Stepper(stepperRef.current);
    }
  }, [stepperRef]);

  useEffect(() => {
    if (event) {
      let enteredCity = `${event?.location?.city?.name}, ${event?.location?.state?.code}, ${
        event?.location?.country?.iso_alpha2
      }`;
      setEventName(event?.name);
      setDescription(event?.description);
      setStartDateTime(
        moment.unix(event?.start_date_time_unix).format('YYYY-MM-DDTHH:mm')
      );
      setEndDateTime(
        moment.unix(event?.end_date_time_unix).format('YYYY-MM-DDTHH:mm')
      );
      setLocationName(event?.location?.title);
      setLocationType(event?.location_type);
      setCity(enteredCity);
      setAddress(event?.location?.addressString);

      const addressObject = {
        address_1: event?.location?.address_1,
        address_2: null,
        zip_code: event?.location?.zip_code,
        lat: event?.location?.lat,
        lng: event?.location?.lng,
      };
      setSelectedAddress(addressObject);
      setSelectedCity({
        city_id: event?.location?.city_id,
        country_id: event?.location?.country_id,
        state_id: event?.location?.state_id,
      });
      setPreview(event?.banner?.fullUrl);
    }
  }, [event]);

  const handleNextStep = (e) => {
    e.preventDefault();
    const form =
      currentStep === 1
        ? formRef1.current
        : currentStep === 2
          ? formRef2.current
          : formRef3.current;
    if (form.checkValidity()) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      form.reportValidity();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);

    try {
      let responseId;
      if (!eventID) {
        const requestObject = {
          name: eventName,
          event_url: null,
          location_type: locationType,
          door_open_date_time_unix: '',
          start_date_time_unix: moment(startDateTime).unix(),
          end_date_time_unix: moment(endDateTime).unix(),
          minimum_age: '',
          description: description,
          accent_color: '',
          duration: '',
          hide_after_expiration: false,
          is_active: 1,
          is_featured: false,
          entity_has_address: {
            title: locationName,
            address_1:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.address_1
                : '',
            address_2:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.address_2
                : '',
            city_id: selectedCity?.city_id,
            state_id: selectedCity?.state_id,
            country_id: selectedCity?.country_id,
            zip_code:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.zip_code
                : '',
            lat:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.lat
                : null,
            lng:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.lng
                : null,
            contact_number: locationType === 'SPECIFIC_LOCATION' ? null : null,
            contact_person_name:
              locationType === 'SPECIFIC_LOCATION' ? null : null,
          },
        };
        const response = await axiosInstance.patch(
          ORGANIZATION_EVENT_ENDPOINT + `${selectedOrg?.id}/event/${event?.id}`,
          requestObject
        );
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseId = response.data.data.id;
        setEventID(response.data.data.id);
      }

      if (image && !imageID) {
        try {
          const imageData = await uploadFile(
            EVENT_CLASS,
            responseId ? responseId : eventID,
            EVENT_BANNER_TYPE,
            image
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }
      await handleNavigation('/events');
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      setIsImageLoading(true);
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);
      if (response.status === 204) {
        setPreview(null);
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsImageLoading(false);
    }
  };

  const handleClearField = () => {
    setEventID(null);
    setCurrentStep(1);
    setGeneralError('');
  };

  return (
    <>
      <div className="page-breadcrumb d-flex align-items-center mb-3">
        <div className="font-22">Edit Info</div>
      </div>
      <div className="">
        <div className="card-body">
          <ul className="nav nav-tabs nav-warning general-ddd" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                data-bs-toggle="tab"
                href="#editform"
                role="tab"
                tabIndex="-1"
                className={`nav-link ${activeTab === 'general' ? 'active' : ''}`}
                onClick={() => handleTabChange('general')}
                aria-selected={activeTab === 'general'}
              >
                <div className="d-flex align-items-center">
                  <div className="tab-title">General Details</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                data-bs-toggle="tab"
                href="#linksocialmedia"
                role="tab"
                tabIndex="-1"
                className={`nav-link ${activeTab === 'linksocialmedia' ? 'active' : ''}`}
                onClick={() => handleTabChange('linksocialmedia')}
                aria-selected={activeTab === 'linksocialmedia'}
              >
                <div className="d-flex align-items-center">
                  <div className="tab-title">Link & Social Media</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                data-bs-toggle="tab"
                href="#tagform"
                className={`nav-link ${activeTab === 'tagform' ? 'active' : ''}`}
                onClick={() => handleTabChange('tagform')}
                role="tab"
                aria-selected={activeTab === 'tagform'}
              >
                <div className="d-flex align-items-center">
                  <div className="tab-title">Tag</div>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content pt-3">
            {activeTab === 'general' && (
              <div
                className={`tab-pane fade ${activeTab === 'general' ? ' active show ' : ''} `}
                id="editform"
                role="tabpanel"
              >
                <div id="stepper8" className="bs-stepper" ref={stepperRef}>
                  <div className="card mb-0">
                    <div className="card-header pe-md-5">
                      <div
                        className="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between tab-ttt"
                        role="tablist"
                      >
                        <div
                          className={`step ${currentStep === 1 ? 'active' : ''}`}
                          data-target="#test-l-1"
                        >
                          <div
                            className="step-trigger"
                            role="tab"
                            id="stepper1trigger1"
                            aria-controls="test-l-1"
                          >
                            <div
                              className={`bs-stepper-circle  ${currentStep === 1 ? 't-a' : 't-a-i'}`}
                            >
                              1
                            </div>
                            <div className="s-v">
                              <h5 className="mb-0 steper-title">The Basice</h5>
                              <p className="mb-0 steper-sub-title">Step 1</p>
                            </div>
                          </div>
                        </div>
                        <div className="bs-stepper-line"></div>
                        <div
                          className={`step ${currentStep === 2 ? 'active' : ''}`}
                          data-target="#test-l-2"
                        >
                          <div
                            className="step-trigger"
                            role="tab"
                            id="stepper1trigger2"
                            aria-controls="test-l-2"
                          >
                            <div
                              className={`bs-stepper-circle  ${currentStep === 2 ? 't-a' : 't-a-i'}`}
                            >
                              2
                            </div>
                            <div className="s-v">
                              <h5 className="mb-0 steper-title">
                                A Little Bit More
                              </h5>
                              <p className="mb-0 steper-sub-title">Step 2</p>
                            </div>
                          </div>
                        </div>
                        <div className="bs-stepper-line"></div>
                        <div
                          className={`step ${currentStep === 3 ? 'active' : ''}`}
                          data-target="#test-l-3"
                        >
                          <div
                            className="step-trigger"
                            role="tab"
                            id="stepper1trigger3"
                            aria-controls="test-l-3"
                          >
                            <div
                              className={`bs-stepper-circle  ${currentStep === 3 ? 't-a' : 't-a-i'}`}
                            >
                              3
                            </div>
                            <div className="s-v">
                              <h5 className="mb-0 steper-title">
                                Visval Appearance
                              </h5>
                              <p className="mb-0 steper-sub-title">Step 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="bs-stepper-content">
                        {/* <form ref={formRef1}> */}
                        <div
                          id="test-l-1"
                          role="tabpanel"
                          className={`bs-stepper-pane ${currentStep === 1 ? 'active' : ''}`}
                          aria-labelledby="stepper1trigger1"
                        >
                          <h5 className="mb-1">
                            Let&apos;s get down to the details
                          </h5>
                          <p className="mb-4"></p>
                          <form ref={formRef1} noValidate>
                            <div className="row g-3">
                              <div className="col-12">
                                <label
                                  htmlFor="EventName"
                                  className="form-label"
                                >
                                  What is the name of your event?
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="EventName"
                                  value={eventName}
                                  required
                                  name="eventName"
                                  onChange={(e) => setEventName(e.target.value)}
                                />
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="EventStartDateTime"
                                  className="form-label"
                                >
                                  When does the event start?
                                </label>
                                <DateTimePicker
                                  value={startDateTime}
                                  onChange={setStartDateTime}
                                  minDate={new Date()}
                                />
                                {/* <input
                                  type="datetime-local"
                                  className="form-control"
                                  id="EventStartDateTime"
                                  value={startDateTime}
                                  required
                                  name="startDateTime"
                                  min={new Date().toISOString().slice(0, 16)}
                                  onChange={(e) => {
                                    setStartDateTime(e.target.value);
                                  }}
                                /> */}
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="EventEndDateTime"
                                  className="form-label"
                                >
                                  And what time does it end?
                                </label>
                                <DateTimePicker
                                  value={endDateTime}
                                  onChange={setEndDateTime}
                                  minDate={new Date()}
                                />
                                {/* <input
                                  type="datetime-local"
                                  className="form-control"
                                  id="EventEndDateTime"
                                  value={endDateTime}
                                  name="endDateTime"
                                  required
                                  min={new Date().toISOString().slice(0, 16)}
                                  onChange={(e) =>
                                    setEndDateTime(e.target.value)
                                  }
                                /> */}
                              </div>
                              <div className="col-12">
                                <label
                                  htmlFor="description"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <textarea
                                  className="form-control"
                                  id="description"
                                  value={description}
                                  required
                                  rows={3}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                ></textarea>
                              </div>
                              <div className="col-12 mt-4">
                                <button
                                  className="btn btn-outline-warning px-4 text-nowrap"
                                  onClick={handleNextStep}
                                  type="button"
                                >
                                  Next
                                  <i className="bx bx-right-arrow-alt ms-2"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div
                          id="test-l-2"
                          role="tabpanel"
                          className={`bs-stepper-pane ${currentStep === 2 ? 'active' : ''}`}
                          aria-labelledby="stepper1trigger2"
                        >
                          <h5 className="mb-1">
                            Where does the event take place?
                          </h5>
                          <p className="mb-4">
                            If there&lsquo;s no single place, just select the
                            most common city.
                          </p>
                          <form ref={formRef2} noValidate>
                            <AddressForm
                              handlePreviousStep={handlePreviousStep}
                              handleNextStep={handleNextStep}
                              locationType={locationType}
                              setLocationType={setLocationType}
                              setSelectedAddress={setSelectedAddress}
                              selectedAddress={selectedAddress}
                              setSelectedCity={setSelectedCity}
                              locationName={locationName}
                              setLocationName={setLocationName}
                              city={city}
                              setCity={setCity}
                              address={address}
                              setAddress={setAddress}
                            />
                          </form>
                        </div>
                        <div
                          id="test-l-3"
                          role="tabpanel"
                          className={`bs-stepper-pane ${currentStep === 3 ? 'active' : ''}`}
                          aria-labelledby="stepper1trigger3"
                        >
                          <h5 className="mb-1">
                            Got an event flyer? You can drop it here!
                          </h5>
                          <p className="mb-4">
                            Give your event its unique touch.
                          </p>
                          <form ref={formRef3} noValidate>
                            <div className="row g-3">
                              <div className="col-12 ">
                                {preview && (
                                  <div className="card bg-secondary-dark p-5 ">
                                    <center>
                                      <img
                                        src={preview}
                                        alt="org"
                                        width={200}
                                        height={200}
                                      />
                                      <br />
                                      <button
                                        type="button"
                                        onClick={(e) =>
                                          handleDelete(e, event?.banner?.id)
                                        }
                                        disabled={isImageLoading}
                                        className="btn btn-outline-danger btn-sm radius-30 px-4 mt-5"
                                      >
                                        <ion-icon name="trash-outline"></ion-icon>
                                        Remove Photo
                                      </button>
                                    </center>
                                  </div>
                                )}
                                {!preview && (
                                  <div className="card bg-secondary-dark p-5">
                                    <input
                                      type="file"
                                      className="form-control"
                                      id="eventFlyer"
                                      required
                                      onChange={(e) =>
                                        setImage(e.target.files[0])
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-12">
                                <div className="d-flex align-items-center gap-3">
                                  <button
                                    className="btn btn-outline-secondary px-4 text-nowrap"
                                    onClick={handlePreviousStep}
                                    type="button"
                                  >
                                    <i className="bx bx-left-arrow-alt me-2"></i>
                                    Previous
                                  </button>
                                  <button
                                    className="btn bg-gradient-branding text-dark px-3 px-md-4 text-nowrap"
                                    onClick={handleSubmit}
                                    type="button"
                                    disabled={isLoading}
                                  >
                                    {isLoading ? 'Loading...' : 'Update'}
                                    <i className="bx bx-right-arrow-alt ms-2"></i>
                                  </button>
                                  {/* <button
                                  className="ms-auto btn btn-outline-warning px-4"
                                  type="button"
                                >
                                  <i className="bx bx-up-arrow-alt ms-2"></i>
                                  Save Draft
                                </button> */}
                                </div>
                                {generalError && (
                                  <div className="error text-center">
                                    {generalError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'linksocialmedia' && (
              <div
                className={`tab-pane fade ${activeTab === 'linksocialmedia' ? ' active show ' : ''} `}
                id="linksocialmedia"
                role="tabpanel"
              >
                <div className="card mb-0">
                  <div className="card-body">
                    <SocialMedia ownerClass={EVENT_CLASS} ownerId={event?.id} />
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'tagform' && (
              <div
                className={`tab-pane fade ${activeTab === 'tagform' ? ' active show ' : ''} `}
                id="tagform"
                role="tabpanel"
              >
                <div className="card mb-0">
                  <div className="card-body">
                    <ChooseTag ownerClass={EVENT_CLASS} ownerId={event?.id} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Edit.propTypes = {
  event: PropTypes.object,
};
export default Edit;
