import React, { useEffect, useState } from 'react';
import { QR_CODE_ENDPOINT } from '../../../../../../Utils/Endpoints';
import axiosInstance from '../../../../../../Utils/axiosInstance';
import Logger from '../../../../../../Utils/Logger';
import jsQR from 'jsqr';
import {
  copyToClipboard,
  downloadBase64Image,
  encrypt,
} from '../../../../../../Utils/common';
import PropTypes from 'prop-types';

const BarCode = ({ barCodeData, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageQRCode, setImageQRCode] = useState(null);

  useEffect(() => {
    fetchQRCode();
  }, []);

  const fetchQRCode = async () => {
    try {
      setIsLoading(true);
      const code = encrypt(type, barCodeData?.id);
      const url = process.env.REACT_APP_FRONT_URL + code;
      const response = await axiosInstance.get(
        QR_CODE_ENDPOINT + `?data=${url}`
      );
      if (response.status === 200) {
        setImageQRCode(response.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = () => {
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Set CORS attribute
    img.src = imageQRCode;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      context.drawImage(img, 0, 0);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        copyToClipboard(code.data, 'Link');
      } else {
        Logger.log('No QR code found!');
      }
    };

    img.onerror = () => {
      Logger.log('Failed to load image');
    };
  };

  // const handleCopyLink = () => {
  //   // Create an Image element
  //   const img = new Image();
  //   img.crossOrigin = 'anonymous';
  //   img.src = imageQRCode;

  //   img.onload = () => {
  //     // Create a canvas to draw the image
  //     const canvas = document.createElement('canvas');
  //     const context = canvas.getContext('2d');
  //     canvas.width = img.width;
  //     canvas.height = img.height;

  //     // Draw the image on the canvas
  //     context.drawImage(img, 0, 0);

  //     // Get the image data from the canvas
  //     const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

  //     // Scan the QR code
  //     const code = jsQR(imageData.data, imageData.width, imageData.height);
  //     if (code) {
  //       copyToClipboard(code.data, 'Link'); // Copy scanned data to clipboard
  //     } else {
  //       Logger.log('No QR code found!');
  //     }
  //   };
  // };

  return (
    <>
      {!isLoading && (
        <div className="col d-flex mb-4 align-items-center gap-2">
          <div className="card radius-10 mb-0">
            <div className="card-body px-2">
              <div className="d-flex align-items-start gap-2">
                <div className="bar-code-set">
                  <img
                    src={imageQRCode ? imageQRCode : '../images/bar-code.png'}
                    alt="imageQRCode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bar-code-text d-flex flex-column gap-2">
            <button
              type="button"
              className="btn btn-outline-info d-block"
              style={{ whiteSpace: 'nowrap' }}
              onClick={() =>
                downloadBase64Image(imageQRCode, barCodeData?.name)
              }
            >
              Save QR code
            </button>

            <button
              type="button"
              className="btn btn-outline-info d-block"
              onClick={handleCopyLink}
            >
              Copy Link
            </button>
          </div>
        </div>
      )}
    </>
  );
};

BarCode.propTypes = {
  barCodeData: PropTypes.object,
  type: PropTypes.string,
};
export default BarCode;
