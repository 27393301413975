import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import BarCode from '../../Components/BarCode';
import PropTypes from 'prop-types';
import {
  EVENT_STATS_ENDPOINT,
  EVENT_TICKET_ENDPOINT,
  GUESTS_LIST_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import Logger from '../../../../../../../Utils/Logger';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import NotFoundPage from '../../../../Components/NotFoundPage';
import RevenueChart from './RevenueChart';

const Summary = ({ event }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [summaryStatsData, setSummaryStatsData] = useState({});
  const [trafficData, setTrafficData] = useState({});
  const [ticketList, setTicketList] = useState([]);
  const [guestList, setGuestList] = useState([]);

  useEffect(() => {
    if (event?.id) {
      fetchSummaryStatsData();
      fetchTrafficData();
      fetchTicket();
      fetchGuest();
    }
  }, []);

  const fetchSummaryStatsData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_STATS_ENDPOINT + `${event?.id}/stats`
      );
      if (response.data.code === 200) {
        setSummaryStatsData(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTicket = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${event.id}/tickets`
      );
      if (response.data.code === 200) {
        setTicketList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGuest = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        GUESTS_LIST_ENDPOINT + `?event_id=${event?.id}`
      );
      if (response.data.code === 200) {
        setGuestList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Views Stats
  const fetchTrafficData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_STATS_ENDPOINT + `${event.id}/traffic/view/graph?year=2024`
      );
      if (response.data.code === 200) {
        setTrafficData(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const state = {
    options: {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false, // Enable toolbar
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: Object.values(trafficData).map((month) => month.monthName),
      },
      // title: {
      //   text: 'Monthly Views Data',
      //   align: 'left',
      // },
      fill: {
        opacity: 1,
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#ff7c43'], // optional
          inverseColors: false,
          stops: [0, 100],
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    },
    series: [
      {
        name: 'Views',
        data: Object.values(trafficData).map((month) => month.totalViews),
      },
    ],
  };

  return (
    <>
      <div className="">
        <div className="row row-cols-1 row-cols-lg-4">
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Total Customer</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-purple">
                    <ion-icon name="wallet-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">
                      {!isLoading && summaryStatsData?.totalCustomerCount
                        ? summaryStatsData?.totalCustomerCount
                        : 0}
                    </h4>
                  </div>
                  <div className="ms-auto"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Total Revenue</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-success">
                    <ion-icon name="bar-chart-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">
                      {!isLoading && summaryStatsData?.totalRevenueFormat
                        ? summaryStatsData?.totalRevenueFormat
                        : '$0.00'}
                    </h4>
                  </div>
                  <div className="ms-auto"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Tickets Sold</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-danger">
                    <ion-icon name="bag-handle-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">
                      {!isLoading && summaryStatsData?.totalTicketSold
                        ? summaryStatsData?.totalTicketSold
                        : 0}
                    </h4>
                  </div>
                  <div className="ms-auto"></div>
                </div>
              </div>
            </div>
          </div>
          <BarCode barCodeData={event} type="EVENT" />
        </div>
        {!isLoading && (
          <div className="card">
            <div className="card-body pb-1">
              <div className="d-flex align-items-center">
                <h6 className="mb-0">Guests</h6>
              </div>
              {!isLoading && guestList?.length === 0 && (
                <NotFoundPage title="Guest" isInfo={true} />
              )}
              {!isLoading && guestList?.length > 0 && (
                <div className="table-responsive mt-3">
                  <table className="table align-middle table-s-c t-w-s">
                    <thead className="table-light">
                      <tr>
                        <th>Sr.no</th>
                        <th className="text-start">Name</th>
                        <th className="text-start">Email</th>
                        <th>Gender</th>
                        <th>DOB</th>
                      </tr>
                    </thead>
                    <tbody>
                      {guestList?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <p>{index + 1}</p>
                          </td>
                          <td className="text-start">
                            <p>{item?.name ? item?.name : '-'}</p>
                          </td>
                          <td className="text-start">
                            <a href={`mailto:${item.email}`}>{item?.email}</a>
                          </td>
                          <td>
                            <p>{item?.genderText}</p>
                          </td>
                          <td>
                            <p>{item?.dob ? item?.dob : '-'}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="card">
            <div className="card-body pb-1">
              <div className="d-flex align-items-center">
                <h6 className="mb-0">Tickets</h6>
              </div>
              {!isLoading && ticketList?.length === 0 && (
                <NotFoundPage title="Ticket" isInfo={true} />
              )}
              {!isLoading && ticketList?.length > 0 && (
                <div className="table-responsive mt-3">
                  <table className="table align-middle table-s-c t-w-s">
                    <thead className="table-light">
                      <tr>
                        <th className="">Sr.no</th>
                        <th className="text-start">Name</th>
                        <th>Price</th>
                        <th>Stock</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketList?.map((item, index) => (
                        <tr key={index}>
                          <td className="">{index + 1}</td>
                          <td className="text-start">
                            <p className="mb-0">{item?.name}</p>
                          </td>
                          <td>
                            {item?.price === 0
                              ? 'Free'
                              : `$${item?.price?.toFixed(2)}`}
                          </td>
                          <td> {item?.stock}</td>

                          <td>
                            <span
                              className={`badge ${item?.status === 'AVAILABLE' ? 'bg-success' : item?.status === 'SOLD_OUT' || item?.status === 'UNAVAILABLE' || item?.status === 'SALE_ENDED' ? 'bg-danger' : 'bg-primary'}`}
                            >
                              {item?.statusName}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-lg-8 d-flex">
            <div className="card radius-10 w-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">Earning Reports</h6>
                  <div className="dropdown options ms-auto d-none">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </div>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" href="">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <RevenueChart
                  dataChart={event}
                  URL={EVENT_STATS_ENDPOINT}
                  revenue={summaryStatsData?.totalRevenueFormat}
                />

                <div className="d-flex flex-column flex-lg-row align-items-lg-center align-self-end justify-content-lg-between border p-3 gap-3 mb-0 rounded-3">
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-success text-success">
                      <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">
                        {!isLoading && summaryStatsData?.totalRevenueFormat
                          ? summaryStatsData?.totalRevenueFormat
                          : '$0.00'}
                      </h4>
                      <p className="mb-0 text-secondary">Total Revenue</p>
                    </div>
                  </div>
                  <div className="vr d-none d-lg-block"></div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-info text-info">
                      <ion-icon name="diamond-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">
                        {!isLoading && summaryStatsData?.totalProfitFormat
                          ? summaryStatsData?.totalProfitFormat
                          : '$0.00'}
                      </h4>
                      <p className="mb-0 text-secondary">Total Profit</p>
                    </div>
                  </div>
                  <div className="vr d-none d-lg-block"></div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-purple text-purple">
                      <ion-icon name="people-circle-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">
                        {!isLoading && summaryStatsData?.totalCustomerCount
                          ? summaryStatsData?.totalCustomerCount
                          : 0}
                      </h4>
                      <p className="mb-0 text-secondary">Total Customer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card radius-10 overflow-hidden w-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">Total Traffic</h6>
                  <div className="dropdown options ms-auto d-none">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex align-items-center font-13 gap-2">
                  <span className="border px-1 rounded cursor-pointer">
                    <i className="bx bxs-circle me-1 text-tiffany"></i>
                    Views
                  </span>
                  <span className="border px-1 rounded cursor-pointer d-none">
                    <i className="bx bxs-circle me-1 text-success"></i>
                    Shares
                  </span>
                </div>
                {/* <div id="chart2"></div> */}
                <Chart
                  options={state.options}
                  series={state.series}
                  type="area"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Summary.propTypes = {
  event: PropTypes.object,
};

export default Summary;
