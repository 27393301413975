import React, { useEffect, useState } from 'react';
import AddGuest from './AddGuest';
import Logger from '../../../../../../../Utils/Logger';
import NotFoundPage from '../../../../Components/NotFoundPage';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import {
  GUESTS_LIST_ENDPOINT,
  GUEST_ORDER_LIST_ENDPOINT,
  ORDER_TICKET_PDF_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import PropTypes from 'prop-types';
import Pagination from '../../../../Components/Pagination';
import NewGuestForm from './NewGuestForm';
import { Link } from 'react-router-dom';

const Guests = ({ event }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [guestList, setGuestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isAddGuest, setIsAddGuest] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState({});
  const [guestView, setGuestView] = useState({});
  const [selectedGuestOrder, setSelectedGuestOrder] = useState({});

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (event?.id) {
      fetchGuest();
    }
  }, [currentPage]);

  const fetchGuest = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        GUESTS_LIST_ENDPOINT +
          `?event_id=${event?.id}&page=${currentPage}&per_page=${itemsPerPage}`
      );
      if (response.data.code === 200) {
        setGuestList(response.data.data.data);
        setTotalItems(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGuestTicket = async (item) => {
    try {
      setIsLoadingOrder(true);
      setGuestView(item);
      const response = await axiosInstance.get(
        GUEST_ORDER_LIST_ENDPOINT +
          `?event_id=${event?.id}&customer_id=${item.id}`
      );
      if (response.data.code === 200) {
        if (response.data.data.data?.length > 0) {
          setSelectedGuestOrder(response.data.data.data[0]);
        } else {
          setSelectedGuestOrder({});
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoadingOrder(false);
    }
  };

  const handleEdit = (item) => {
    setIsAddGuest(true);
    setIsAdd(false);
    setSelectedGuest(item);
  };

  const handleAdd = () => {
    setIsAdd(!isAdd);
    setIsAddGuest(false); // Close New Guest if it's open
    setSelectedGuest({});
  };

  const handleAddGuest = () => {
    setIsAddGuest(true);
    setIsAdd(false); // Close Assign if it's open
    setSelectedGuest({});
  };

  const handleBack = () => {
    setIsAdd(false);
    setIsAddGuest(false); // Reset both states to show default buttons
    setSelectedGuest({});
  };

  const downloadTicket = async (e) => {
    e.preventDefault();
    try {
      setIsDownloadLoading(true);
      const response = await axiosInstance.get(
        ORDER_TICKET_PDF_ENDPOINT + `${selectedGuestOrder?.id}`,
        { responseType: 'blob' }
      );

      if (response.status === 200) {
        // Create a new Blob object using the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ticket_${selectedGuestOrder?.id}.pdf`);

        // Append to the body
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setSelectedGuestOrder({});
    setSelectedGuest({});
  };
  return (
    <>
      <div className="page-breadcrumb d-flex align-items-center mb-3 justify-content-between gap-3 flex-wrap">
        <div className="font-22">
          {isAdd && 'Assign'}
          {isAddGuest && !selectedGuest?.id && 'New Guest'}
          {isAddGuest && selectedGuest?.id && 'Edit Guest'}
          {!isAddGuest && !isAdd && 'Guests'}
        </div>
        {isLoadingOrder && <div className="loader"></div>}
        {/* Show both buttons by default */}
        {!isAdd && !isAddGuest && (
          <>
            <div className="d-flex gap-3">
              <a className="btn btn-outline-primary" onClick={handleAdd}>
                Assign Guest
              </a>
              <a className="btn btn-outline-info" onClick={handleAddGuest}>
                <i className="bx bx-plus"></i>Create Guest
              </a>
            </div>
          </>
        )}

        {/* Show Back button when either Assign or New Guest is clicked */}
        {(isAdd || isAddGuest) && (
          <a className="btn btn-outline-secondary ms-auto" onClick={handleBack}>
            <i className="bx bx-left-arrow-alt"></i> Back
          </a>
        )}
      </div>

      {!isAdd && !isAddGuest && (
        <>
          {!isLoading && guestList?.length > 0 && (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive mt-3">
                        <table className="table align-middle table-s-c t-w-s-4">
                          <thead className="table-light">
                            <tr>
                              <th>Sr.No</th>
                              <th className="text-start">Name</th>
                              <th className="text-start">Email</th>
                              <th>Gender</th>
                              {/* <th>DOB</th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {guestList?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p>
                                    {(currentPage - 1) * itemsPerPage +
                                      index +
                                      1}
                                  </p>
                                </td>
                                <td className="text-start">
                                  <p>{item?.name ? item?.name : '-'}</p>
                                </td>

                                <td className="text-start">
                                  <a href={`mailto:${item.email}`}>
                                    {item?.email}
                                  </a>
                                </td>
                                <td>
                                  <p>{item?.genderText}</p>
                                </td>
                                <td>
                                  <div className="table-actions d-flex align-items-center justify-content-center gap-2 fs-6">
                                    {item?.is_editable === 1 ? (
                                      <div
                                        className="widget-icon d-none rounded-circle bg-light-primary text-primary"
                                        onClick={() => handleEdit(item)}
                                      >
                                        <ion-icon
                                          name="create-outline"
                                          role="img"
                                          class="md hydrated"
                                        ></ion-icon>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div
                                      className={`widget-icon rounded-circle bg-light-info text-info ${guestView?.id === item?.id ? 'card-dis-icon' : ''}`}
                                      onClick={
                                        guestView?.id !== item?.id
                                          ? () => handleGuestTicket(item)
                                          : null
                                      }
                                    >
                                      <ion-icon
                                        name="eye-outline"
                                        role="img"
                                        class="md hydrated"
                                      ></ion-icon>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {Object.keys(selectedGuestOrder)?.length > 0 ? (
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="qr-name">
                          <div className="name-id">
                            <label htmlFor="name">
                              #{selectedGuestOrder?.order_number}
                            </label>
                            <div className="">
                              {/* <label htmlFor="name">Name</label> */}
                              <h4 className="text-white">
                                {selectedGuestOrder?.user?.name}
                              </h4>
                            </div>
                          </div>
                          {selectedGuestOrder?.qr && (
                            <div className="qr-icon">
                              <img
                                src="../images/bar-code.png"
                                alt="bar-code"
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="user-d">
                          <label htmlFor="email">Email</label>
                          <p>
                            <Link
                              to={`mailto:${selectedGuestOrder?.user?.email}`}
                            >
                              {selectedGuestOrder?.user?.email}
                            </Link>
                          </p>
                          <div className="g-g pt-2">
                            <div>
                              <label htmlFor="gender">Gender</label>
                              <p className="text-white">
                                {selectedGuestOrder?.user?.genderText}
                              </p>
                            </div>
                            <div className="text-end">
                              <label htmlFor="gender">DOB</label>
                              <p className="text-white">
                                {selectedGuestOrder?.user?.dob
                                  ? selectedGuestOrder?.user?.dob
                                  : '-'}
                              </p>
                            </div>
                          </div>
                          {selectedGuestOrder?.order_items?.map(
                            (item, index) => (
                              <div className="g-g pt-2" key={index}>
                                <div>
                                  <label htmlFor="gender">Ticket Name</label>
                                  <p className="text-white">
                                    {item?.holder?.name}
                                  </p>
                                </div>
                                <div className="text-end">
                                  <label htmlFor="gender">
                                    Ticket Quantity
                                  </label>
                                  <p className="text-white">{item?.quantity}</p>
                                </div>
                              </div>
                            )
                          )}
                          <div className="g-g g-g-2">
                            <Link
                              className="btn btn-outline-danger text-nowrap d-none"
                              onClick={handleClose}
                            >
                              Close
                            </Link>
                            <div
                              className={`btn btn-outline-info text-nowrap ${guestView?.is_editable === 1 ? '' : 'card-dis-btn'}`}
                              onClick={() => handleEdit(guestView)}
                            >
                              Edit
                            </div>

                            <div className="widget-icon rounded-circle bg-light-info text-info d-none">
                              <ion-icon
                                name="create-outline"
                                role="img"
                                class="md hydrated"
                              ></ion-icon>
                            </div>
                            <Link
                              className="btn btn-outline-warning text-nowrap"
                              onClick={downloadTicket}
                            >
                              {isDownloadLoading
                                ? 'Loading...'
                                : 'Download Ticket'}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="c-m-t">
                          <p>
                            <ion-icon name="people-circle-outline"></ion-icon>
                          </p>
                          <p>
                            To view and make changes to guests, click on the
                            view icon.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {totalItems > 10 && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )}
            </>
          )}
        </>
      )}

      {!isAdd && !isAddGuest && (
        <>
          {!isLoading && guestList?.length === 0 && (
            <NotFoundPage title="Guest" />
          )}
        </>
      )}

      {isAdd && !isAddGuest && (
        <AddGuest event={event} fetchGuest={fetchGuest} handleAdd={handleAdd} />
      )}

      {isAddGuest && (
        <NewGuestForm
          selectedGuest={selectedGuest}
          fetchGuest={fetchGuest}
          handleClose={() => {
            setIsAddGuest(false);
            setSelectedGuest({});
          }}
        />
      )}
    </>
  );
};

Guests.propTypes = {
  event: PropTypes.object,
};

export default Guests;
