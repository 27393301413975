import React, { useEffect, useRef, useState } from 'react';
import Layout from './Components/Layout';
import { Link, useNavigate } from 'react-router-dom';
import Stepper from 'bs-stepper';
import axiosInstance from '../../../Utils/axiosInstance';
import { ORGANIZATION_ENDPOINT } from '../../../Utils/Endpoints';
import { ORGANIZATION_BANNER_TYPE } from '../../../Utils/UploadType';
import { ORGANIZATION_CLASS } from '../../../Utils/ModelClasses';
import { uploadFile } from '../../../Utils/upload';
import AddressForm from '../../Components/AddressForm';

const CreateOrganization = () => {
  const [locationType, setLocationType] = useState('SPECIFIC_LOCATION'); // Default to SPECIFIC_LOCATION
  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [locationName, setLocationName] = useState('');
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [orgID, setOrgID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const formRef1 = useRef(null);
  const formRef2 = useRef(null);
  const formRef3 = useRef(null);

  const stepperRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  useEffect(() => {
    if (stepperRef.current) {
      // Initialize your stepper here
      new Stepper(stepperRef.current);
    }
  }, [stepperRef]);

  const handleNextStep = (e) => {
    e.preventDefault();
    const form =
      currentStep === 1
        ? formRef1.current
        : currentStep === 2
          ? formRef2.current
          : formRef3.current;
    if (form.checkValidity()) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      form.reportValidity();
    }
  };
  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      let responseId;
      if (!orgID) {
        const requestObject = {
          name: organizationName,
          org_url: '',
          business_type: businessType,
          location_type: locationType,
          minimum_age: '',
          description: description,
          accent_color: '',
          is_active: 1,
          is_featured: false,
          entity_has_address: {
            title: locationName,
            address_1:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.address_1
                : '',
            address_2:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.address_2
                : '',
            city_id: selectedCity?.city_id,
            state_id: selectedCity?.state_id,
            country_id: selectedCity?.country_id,
            zip_code:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.zip_code
                : '',
            lat:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.lat
                : null,
            lng:
              locationType === 'SPECIFIC_LOCATION'
                ? selectedAddress?.lng
                : null,
            contact_number: locationType === 'SPECIFIC_LOCATION' ? null : null,
            contact_person_name:
              locationType === 'SPECIFIC_LOCATION' ? null : null,
          },
        };

        const response = await axiosInstance.post(
          ORGANIZATION_ENDPOINT,
          requestObject
        );
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseId = response.data.data.id;
        setOrgID(response.data.data.id);
      }

      if (image && !imageID) {
        try {
          let imageData = await uploadFile(
            ORGANIZATION_CLASS,
            responseId ? responseId : orgID,
            ORGANIZATION_BANNER_TYPE,
            image
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }
      await handleNavigation('/organiziations');
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Layout>
        <div className="page-breadcrumb d-flex align-items-center mb-3">
          <div className="font-22">Create New Organization</div>
          <Link
            to="/organiziations"
            className="btn btn-outline-secondary ms-auto"
          >
            <i className="bx bx-left-arrow-alt"></i> Back
          </Link>
        </div>
        <div id="stepper1" className="bs-stepper" ref={stepperRef}>
          <div className="card">
            <div className="card-header pe-md-5">
              <div
                className="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between tab-ttt"
                role="tablist"
              >
                <div
                  className={`step ${currentStep === 1 ? 'active' : ''}`}
                  data-target="#test-l-1"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger1"
                    aria-controls="test-l-1"
                  >
                    <div className="bs-stepper-circle">1</div>
                    <div className="s-v">
                      <h5 className="mb-0 steper-title">The Basics</h5>
                      <p className="mb-0 steper-sub-title">Step 1</p>
                    </div>
                  </div>
                </div>
                <div className="bs-stepper-line"></div>
                <div
                  className={`step ${currentStep === 2 ? 'active' : ''}`}
                  data-target="#test-l-2"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger2"
                    aria-controls="test-l-2"
                  >
                    <div className="bs-stepper-circle">2</div>
                    <div className="s-v">
                      <h5 className="mb-0 steper-title">A Little Bit More</h5>
                      <p className="mb-0 steper-sub-title">Step 2</p>
                    </div>
                  </div>
                </div>
                <div className="bs-stepper-line"></div>
                <div
                  className={`step ${currentStep === 3 ? 'active' : ''}`}
                  data-target="#test-l-3"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger3"
                    aria-controls="test-l-3"
                  >
                    <div className="bs-stepper-circle">3</div>
                    <div className="s-v">
                      <h5 className="mb-0 steper-title">Visual Appearance</h5>
                      <p className="mb-0 steper-sub-title">Step 3</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="bs-stepper-content">
                <div className="tab-content py-3"></div>
                <div
                  id="test-l-1"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 1 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger1"
                >
                  <h5 className="mb-1">
                    What is the name of your organization?
                  </h5>
                  <p className="mb-4">
                    It can be a name of a brand, a company or any name you do
                    your business as.
                  </p>

                  <form ref={formRef1} noValidate>
                    <div className="row g-3">
                      <div className="col-12">
                        <label
                          htmlFor="OrganizationName"
                          className="form-label"
                        >
                          Organization Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="OrganizationName"
                          value={organizationName}
                          required
                          onChange={(e) => setOrganizationName(e.target.value)}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="LastName" className="form-label">
                          What describes best the type of your business?
                        </label>
                        <div className="row g-3 row-cols-1 row-cols-lg-2">
                          <div className="col">
                            <div className="form-check form-check-info hover-border-info border-1 card py-4 px-2 bg-secondary-dark text-center user-select-none">
                              <input
                                className="form-check-input scale m-auto mb-3"
                                name="businessType"
                                type="radio"
                                value="BRAND"
                                required
                                id="brandCheck"
                                checked={businessType === 'BRAND'}
                                onChange={(e) =>
                                  setBusinessType(e.target.value)
                                }
                              />
                              <label
                                className="form-check-label text-white font-18"
                                htmlFor="brandCheck"
                              >
                                Brand
                                <small className="d-block text-info font-13">
                                  You run or host events/parties under your own
                                  brand.
                                </small>
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-check form-check-info card hover-border-info border-1 py-4 px-2 bg-secondary-dark text-center user-select-none">
                              <input
                                className="form-check-input scale m-auto mb-3"
                                name="businessType"
                                type="radio"
                                value="NIGHTCLUB"
                                id="nightClubCheck"
                                checked={businessType === 'NIGHTCLUB'}
                                onChange={(e) =>
                                  setBusinessType(e.target.value)
                                }
                              />
                              <label
                                className="form-check-label text-white font-18"
                                htmlFor="nightClubCheck"
                              >
                                Nighclub
                                <small className="d-block text-info font-13">
                                  You run your own events or host other’s events
                                  at a consistent location.
                                </small>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-0">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="description"
                          value={description}
                          required
                          rows={3}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="col-12 col-lg-6">
                        <button
                          className="btn btn-outline-warning px-4"
                          type="button"
                          onClick={handleNextStep}
                        >
                          Next<i className="bx bx-right-arrow-alt ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  id="test-l-2"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 2 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger2"
                >
                  <h5 className="mb-1">
                    Where does the organization usually operate?
                  </h5>
                  <p className="mb-4">
                    If there&lsquo;s no single place, just select the most
                    common city.
                  </p>
                  <form ref={formRef2} noValidate>
                    <AddressForm
                      handlePreviousStep={handlePreviousStep}
                      handleNextStep={handleNextStep}
                      locationType={locationType}
                      setLocationType={setLocationType}
                      setSelectedAddress={setSelectedAddress}
                      selectedAddress={selectedAddress}
                      setSelectedCity={setSelectedCity}
                      locationName={locationName}
                      setLocationName={setLocationName}
                      city={city}
                      setCity={setCity}
                      address={address}
                      setAddress={setAddress}
                    />
                  </form>
                </div>
                <div
                  id="test-l-3"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 3 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger3"
                >
                  <h5 className="mb-1">Choose cover image!</h5>
                  <p className="mb-4">
                    Make your organization stand out with a great image and an
                    accent color.{' '}
                  </p>
                  <form ref={formRef3} noValidate>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="card bg-secondary-dark p-5">
                          <input
                            type="file"
                            className="form-control"
                            id="image"
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3">
                          <button
                            className="btn btn-outline-secondary px-4"
                            onClick={handlePreviousStep}
                            type="button"
                          >
                            <i className="bx bx-left-arrow-alt me-2"></i>
                            Previous
                          </button>
                          <button
                            className="btn bg-gradient-branding text-dark px-4"
                            onClick={handleSubmit}
                            disabled={isLoading}
                          >
                            {isLoading ? 'Loading...' : 'Publish'}
                            <i className="bx bx-right-arrow-alt ms-2"></i>
                          </button>
                        </div>
                        {generalError && (
                          <div className="error text-center">
                            {generalError}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreateOrganization;
